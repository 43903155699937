import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputMask from 'react-input-mask';

import { Form, Input, Radio, Spin } from 'antd';

import { MainService } from 'src/services/MainServices';

import { IUser } from 'src/redux/UserReducer';

import { Button } from 'src/components/atoms';

const ProfileEdit: React.FC = () => {
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const user = useSelector((state: { user: IUser }) => state.user);

	const dispatch = useDispatch();

	const onFinish = (values: IUser) => {
		setLoading(true);
		MainService.fetch('users/profile', 'post', values).then(() =>
			setLoading(false)
		);
		dispatch({ type: 'USER_UPDATE', value: { ...user, ...values } });
	};

	return (
		<Spin spinning={loading}>
			<Form
				initialValues={{
					name: user.name,
					email: user.email,
					phone: user.phone,
					sex: user.sex,
				}}
				layout='vertical'
				form={form}
				onFinish={onFinish}
				data-testid='form-sign'>
				<Form.Item
					label='Nome Completo'
					name='name'
					rules={[{ required: true, message: 'Digite seu nome completo' }]}>
					<Input
						placeholder='Digite seu nome completo'
						data-testid='input-name'
					/>
				</Form.Item>

				<Form.Item
					label='Email'
					name='email'
					rules={[{ required: true, message: 'Digite seu email' }]}>
					<Input
						placeholder='Digite seu email'
						data-testid='input-email'
						type='email'
					/>
				</Form.Item>

				<Form.Item
					label='Telefone (WhatsApp)'
					name='phone'
					rules={[{ required: true, message: 'Digite seu telefone' }]}>
					<InputMask
						mask='(99) 99999-9999'
						placeholder='(00) 00000-0000'
						type='tel'
						data-testid='input-phone'>
						{(inputProps: {
							placeholder: string;
							disabled: boolean;
							type: string;
						}) => <Input {...inputProps} />}
					</InputMask>
				</Form.Item>

				<Form.Item label='Orientação sexual' name='sex'>
					<Radio.Group
						options={[
							{
								label: 'Masculino',
								value: 'masculino',
							},
							{
								label: 'Feminino',
								value: 'feminino',
							},
							{
								label: 'Não Opinar',
								value: 'outro',
							},
						]}
						optionType='button'
					/>
				</Form.Item>

				<div className='mt-2'>
					<Button container type='submit'>
						SALVAR
					</Button>
				</div>
			</Form>
		</Spin>
	);
};

export default ProfileEdit;
