import React from 'react';
import ButtonStyle from './ButtonStyle';

export interface IButton
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	container?: boolean;
	loaded?: boolean;
	icon?: JSX.Element;
	color?: 'primary' | 'secondary' | 'tertiary' | 'transparent' | 'white';
	format?: 'solid' | 'ghost';
	size?: 'small' | 'normal' | 'large';
}

export const Button: React.FC<IButton> = ({ children, ...props }) => {
	return (
		<ButtonStyle {...props}>
			{props.icon && <span className='icon'>{props.icon}</span>}
			{children}
		</ButtonStyle>
	);
};
