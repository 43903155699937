import React from 'react';
import OnBoardingPaginateStyle from './OnBoardingPaginateStyle';

interface Props {
	paged: number;
	total: number;
}

const OnBoardingPaginate: React.FC<Props> = ({ total, paged }) => {
	const pages: Array<JSX.Element> = [];

	for (let index = 0; index < total; index++) {
		pages.push(
			<span
				key={`paged-${index}`}
				className={index === paged ? 'active' : ''}
			/>
		);
	}

	return (
		<OnBoardingPaginateStyle.Wrapper>
			{pages.map((item) => item)}
		</OnBoardingPaginateStyle.Wrapper>
	);
};

export default OnBoardingPaginate;
