import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Drawer } from 'antd';

import AuthService from 'src/services/AuthService';

import { IUser } from 'src/redux/UserReducer';

import logo from 'src/assets/images/logo.svg';
import logobw from 'src/assets/images/logo-bw.svg';

import HeaderPrivateStyle from './HeaderPrivateStyle';

import { Button } from 'src/components/atoms';

interface IHeaderPrivate {
	back?: boolean;
	transparent?: boolean;
	helper?: React.ReactNode;
}

export const HeaderPrivate: React.FC<IHeaderPrivate> = ({
	children,
	transparent,
	helper,
	back,
}) => {
	const user = useSelector((state: { user: IUser }) => state.user);
	const [modalMenu, setModalMenu] = useState(false);

	const navigate = useNavigate();

	const onClickBack = (): void => {
		window.history.back();
	};

	const onLogout = (): void => {
		AuthService.logout();
	};
	return (
		<>
			<HeaderPrivateStyle.Wrapper transparent={transparent}>
				{back && (
					<HeaderPrivateStyle.Back transparent={transparent}>
						<button onClick={onClickBack} data-testid='header-private-button'>
							<i className='ri-arrow-left-line'></i>
						</button>
					</HeaderPrivateStyle.Back>
				)}
				{children ? (
					<HeaderPrivateStyle.Title>{children}</HeaderPrivateStyle.Title>
				) : (
					<HeaderPrivateStyle.Logo>
						<img
							src={transparent ? logobw : logo}
							alt='Desafio Renovit'
							onClick={() => navigate('/app/dashboard')}
						/>
					</HeaderPrivateStyle.Logo>
				)}
				{helper && <div className='helper'>{helper}</div>}

				<HeaderPrivateStyle.Menu onClick={() => setModalMenu(!modalMenu)}>
					<i className='ri-menu-3-fill' />
				</HeaderPrivateStyle.Menu>
			</HeaderPrivateStyle.Wrapper>
			<Drawer visible={modalMenu} onClose={() => setModalMenu(!modalMenu)}>
				<HeaderPrivateStyle.CardUser>
					<NavLink to='/app/profile'>
						<figure>
							<img
								src={
									user.imageUrl ||
									`https://avatar.oxro.io/avatar.svg?name=${encodeURI(
										user.name || ''
									)}&background=A9EE56&caps=3&bold=true`
								}
								alt={user.name}
							/>
						</figure>
					</NavLink>

					<dl>
						<dt>{user.name}</dt>
						<dd>{user.email}</dd>
					</dl>

					<Button
						container
						format='ghost'
						color='transparent'
						size='small'
						onClick={onLogout}>
						SAIR
					</Button>
				</HeaderPrivateStyle.CardUser>
			</Drawer>
		</>
	);
};

export default HeaderPrivate;
