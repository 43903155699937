export interface IMisc {
	loading: boolean;
	activityDay?: number;
	statusMobile?: string;
	tokenSignUp?: string;
}

const MiscReducer: (
	state: IMisc,
	action: {
		type:
			| 'MISC_UPDATE'
			| 'MISC'
			| 'TOGGLE_LOADING'
			| 'LOADING_SHOW'
			| 'LOADING_HIDE'
			| 'SET_ACTIVITY_DAY'
			| 'OPEN_RECEITA'
			| 'CLOSE_RECEITA'
			| 'UPDATE_TOKEN_SIGN_UP'
			| 'UPDATE_MISC_STATUS';
		value: any;
	}
) => IMisc = (
	state = {
		loading: false,
		activityDay: 0,
		statusMobile: '',
		tokenSignUp: '',
	},
	action
) => {
	switch (action.type) {
		case 'TOGGLE_LOADING':
			return { ...state, loading: !state.loading };

		case 'LOADING_SHOW':
			return { ...state, loading: true };

		case 'LOADING_HIDE':
			return { ...state, loading: false };

		case 'SET_ACTIVITY_DAY':
			return {
				...state,
				activityDay: action.value,
			};

		case 'CLOSE_RECEITA':
			return {
				...state,
				receitaModal: {
					id: '',
					visible: false,
				},
			};

		case 'OPEN_RECEITA':
			return {
				...state,
				receitaModal: {
					id: action.value,
					visible: true,
				},
			};

		case 'UPDATE_TOKEN_SIGN_UP':
			return {
				...state,
				tokenSignUp: action.value,
			};

		case 'UPDATE_MISC_STATUS':
			return {
				...state,
				statusMobile: action.value,
			};

		default:
			return state;
	}
};

export default MiscReducer;
