import styled from '@emotion/styled';

const ActivitiesCardStyle = {
	Wrapper: styled.div(
		({ theme }) => `
        background: ${theme.colors.build.light.primary};
        border-radius: ${theme.decoration.border.radius.md};
        box-shadow: ${theme.decoration.shadow.default};
        margin: 0 0 24px;	
    `
	),

	Header: styled.div`
		padding: 24px;
	`,

	Content: styled.div<{ show: boolean }>(
		({ theme, show }) => `
            border-top: 1px solid ${theme.colors.build.gray.g200};
            position: relative;

            display: ${show ? 'block;' : 'none'};
        `
	),

	CardItem: styled.div(
		({ theme }) => `
        
        span.radio {
            color: ${theme.colors.build.dark.primary};
            display: block;
            font-size: 24px;
            height: 24px;
            line-height: 1;
            position: absolute;
            transition: all 0.3s;
            width: 24px;
        }

        ul {
            ${theme.typography.body.standard}
            list-style: none;
            margin: 0;
            padding: 0;

            &.tasks {
                li {
                    ${theme.typography.body.standard}
                    padding: 16px 32px 16px 64px;
                    position: relative;
                    transition: all 0.3s;

                    em {
                        font-style: normal;
                    }

                    span.radio {
                        cursor: pointer;
                        left: 32px;
                        top: 15px;
                    }

                    a {
                        ${theme.typography.body.short}
                        background: ${theme.colors.gradient.g002};
                        border-radius: ${theme.decoration.border.radius.sm};
                        color: ${theme.colors.build.dark.primary};
                        display: inline-block;
                        font-weight: bold;
                        line-height: normal;
                        margin-left: 8px;
                        padding: 4px 8px;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }

                    &:nth-of-type(even) {
                        background: ${theme.colors.build.gray.g100};
                    }

                    &.checked {
                        span.radio {
                            color: ${theme.colors.action.success};
                        }

                        em {
                            text-decoration: line-through;
                        }
                    }
                }
            }

            &.media {
                li {
                    border-bottom: 1px dashed ${theme.colors.build.gray.g200};
                    padding: 16px 32px;
                    position: relative;

                    .action {
                        margin-right: 16px;
                        width: 48px;
                    }

                    .buttonPlay {
                        align-items: center;
                        background: ${theme.colors.build.brand.b003};
                        border-radius: 100%;
                        border: none;
                        color: ${theme.colors.build.light.primary};
                        display: flex;
                        font-size: 32px;
                        height: 48px;
                        justify-content: center;
                        padding: 0;
                        width: 48px;
                    }

                    h3 {
                        ${theme.typography.body.standard}
                        font-weight: bold;
                        margin: 0;
                        overflow-wrap: break-word;
                    }
                }
            }
        }

        p {
            ${theme.typography.body.standard}
            font-style: italic;
        }
	`
	),
};

export default ActivitiesCardStyle;
