import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Form, message, Modal, Input, Radio, Spin } from 'antd';

import InputMask from 'react-input-mask';

import SignStyle from 'src/pages/sign/SignStyle';

import { MainService } from 'src/services/MainServices';
import { Mixpanel } from 'src/services/Mixpanel';
import { Button } from '../atoms';

interface FormSign {
	email: string;
	name: string;
	password: string;
	phone: string;
	sex: string;
}

const SignUser = () => {
	const navigate = useNavigate();

	const [form] = Form.useForm();

	const [isLoading, setLoading] = useState(false);

	const onFinish = async (values: FormSign) => {
		try {
			if (!values.name) {
				message.error('Insira um nome válido');
				return false;
			}

			if (!values.email || !values.password || !values.phone) {
				message.error('Insira um email válido');
				return false;
			}

			if (!values.password) {
				message.error('Insira uma senha válida');
				return false;
			}

			if (!values.phone) {
				message.error('Insira um telefone válido');
				return false;
			}

			setLoading(true);

			const res = await MainService.fetch('users', 'post', values);

			if (res.status === 'success') {
				Mixpanel.track('Cadastro - Evento', {
					status: res.status,
					email: values.email,
					name: values.name,
				});

				message.success('Cadastro realizado com sucesso');
				navigate('/');
			} else {
				let message =
					'Erro ao realizar seu cadastro, tente novamente mais tarde';

				if (res.error && res.error.keyPattern) {
					message = res.error.keyPattern.email
						? 'Email já cadastrado'
						: 'Erro no cadastro, tente novamente mais tade';
				} else if (res.data && res.data.message) {
					message = res.data.message;
				}

				Modal.error({
					title: 'Erro',
					content: message,
				});

				setLoading(false);
			}
		} catch (error) {
			Modal.error({
				title: 'Erro',
				content: 'Erro ao realizar seu cadastro, tente novamente mais tarde.',
			});
			setLoading(false);

			throw error;
		}
	};

	return (
		<Spin spinning={isLoading}>
			<SignStyle.HeroText>
				<dt>Cadastro</dt>
				<dd>
					Seu e-mail ainda não foi cadastrado, cadastre-se agora e inicie o{' '}
					<strong>Rennovit Detox Week</strong>.
				</dd>
			</SignStyle.HeroText>
			<Form
				layout='vertical'
				form={form}
				onFinish={onFinish}
				data-testid='form-sign'>
				<Form.Item
					label='Nome Completo'
					name='name'
					rules={[{ required: true, message: 'Digite seu nome completo' }]}>
					<Input
						placeholder='Digite seu nome completo'
						disabled={isLoading}
						data-testid='input-name'
					/>
				</Form.Item>

				<Form.Item
					label='Email'
					name='email'
					rules={[
						{ required: true, message: 'Digite seu email', type: 'email' },
					]}>
					<Input
						placeholder='Digite seu email'
						disabled={isLoading}
						data-testid='input-email'
					/>
				</Form.Item>

				<Form.Item
					label='Telefone (WhatsApp)'
					name='phone'
					rules={[{ required: true, message: 'Digite seu telefone' }]}>
					<InputMask
						mask='(99) 99999-9999'
						placeholder='(00) 00000-0000'
						type='tel'
						data-testid='input-phone'>
						{(inputProps: {
							placeholder: string;
							disabled: boolean;
							type: string;
						}) => <Input {...inputProps} />}
					</InputMask>
				</Form.Item>

				<Form.Item label='Orientação sexual' name='sex'>
					<Radio.Group
						options={[
							{
								label: 'Masculino',
								value: 'masculino',
							},
							{
								label: 'Feminino',
								value: 'feminino',
							},
							{
								label: 'Não Opinar',
								value: 'outro',
							},
						]}
						optionType='button'
					/>
				</Form.Item>

				<Form.Item
					label='Senha'
					name='password'
					rules={[
						{ required: true, message: 'Digite sua senha' },
						{ min: 6, message: 'Senha tem que ter no mínimo 6 digitos' },
					]}>
					<Input.Password disabled={isLoading} data-testid='input-pass' />
				</Form.Item>

				<div
					className='pt-3'
					style={{
						textAlign: 'center',
					}}>
					<Button
						container
						type='submit'
						disabled={isLoading}
						loaded={isLoading}>
						CADASTRAR
					</Button>

					<div className='pt-3'>
						<SignStyle.LinkSmall onClick={() => window.history.back()}>
							Voltar
						</SignStyle.LinkSmall>
					</div>
				</div>
			</Form>
		</Spin>
	);
};

export default SignUser;
