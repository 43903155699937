import React from 'react';

import SignError from 'src/layout/SignLayout/SignError';

import Ilustra from 'src/assets/images/illustra-error-code.png';
import SignLayoutStyle from 'src/layout/SignLayout/SignLayoutStyle';

const SignErrorInvoice: React.FC = () => {
	return (
		<SignError>
			<SignLayoutStyle.ErrorCode>
				<figure>
					<img src={Ilustra} alt='Código Inválido' />
				</figure>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo,
					velit vel curabitur neque, quam.
				</p>
			</SignLayoutStyle.ErrorCode>
		</SignError>
	);
};

export default SignErrorInvoice;
