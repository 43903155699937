import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { Helpers } from 'src/utils/helpers';

import { useRedux } from 'src/redux';
import { useDispatch } from 'react-redux';
import { MainService } from 'src/services';

function useSubscription() {
	const dispatch = useDispatch();

	const [progressPercent, setProgressPercent] = useState(0);
	const [progressChecked, setProgressChecked] = useState(0);
	const [dateEnd, setDateEnd] = useState(moment());

	const { challenge, subscription } = useRedux();

	const formatDataStart = () => {
		const start = moment(subscription.challengeStart).set({
			hour: 0,
			minute: 0,
			second: 1,
		});

		if (moment(start).isoWeekday() === 7) {
			return start.day(1).format();
		}

		return start.add(1, 'week').day(1).format();
	};

	const dateStart = formatDataStart();

	const checkSubscription = useCallback(
		(day: number): boolean => {
			if (subscription.schedule && subscription.schedule?.length) {
				const dayIndex = subscription.schedule.findIndex((item) => {
					if (item) {
						return item.day === day;
					}
					return false;
				});

				if (subscription.schedule[dayIndex]) {
					let check = true;
					subscription.schedule[dayIndex].goingsOn.forEach((going) => {
						if (going.activities.tasks.length) {
							const tasks: number = going.activities.tasks.length || 0;
							let size: number = 0;
							going.activities.tasks.forEach((item) => {
								size += item.completed ? 1 : 0;
							});
							if (tasks === 0 || tasks !== size) {
								check = false;
							}
						}
					});
					return check;
				}
				return false;
			} else {
				return false;
			}
		},
		[subscription.schedule]
	);

	const checkProgress = useCallback((): void => {
		let challengeItems = 0;
		let subscriptionItems = 0;

		if (
			challenge.schedule &&
			challenge.schedule.length &&
			subscription.schedule &&
			subscription.schedule.length
		) {
			challenge.schedule?.forEach((schedule) => {
				schedule.goingsOn.forEach((item) => {
					challengeItems +=
						item.activities &&
						item.activities.tasks &&
						item.activities.tasks.length
							? item.activities.tasks.length
							: 0;
				});
			});

			if (subscription.schedule) {
				subscription.schedule?.forEach((schedule) => {
					if (schedule && schedule.goingsOn) {
						schedule.goingsOn?.forEach((goingsOn) => {
							goingsOn.activities.tasks.forEach((item) => {
								subscriptionItems += item.completed ? 1 : 0;
							});
						});
					}
				});
			}
		}

		const resultPercent = (subscriptionItems * 100) / challengeItems;
		setProgressPercent(resultPercent || 0);
	}, [challenge.schedule, subscription.schedule]);

	const getDay = (day: number) => {
		if (subscription.schedule) {
			return subscription.schedule.find((item) => item.day === day);
		}
	};

	const checkChallenge = () => {
		MainService.fetch('subscription/check').then((res) => {
			if (res.status === 'success') {
				dispatch({ type: 'CHALLENGE_UPDATE', value: res.challenge });
				dispatch({
					type: res.data.challengeStart
						? 'SUBSCRIPTION_NEW'
						: 'SUBSCRIPTION_RESET',
					value: res.data,
				});
			}
		});
	};

	const avaliableActivity = (indexActivity: number) =>
		indexActivity < Helpers.activitieNumber(dateStart);

	useEffect(() => {
		let progress = 0;

		challenge.schedule?.forEach((item) => {
			progress += checkSubscription(item.day) ? 1 : 0;
		});

		setProgressChecked(progress);

		setDateEnd(moment(dateStart).add(challenge.days, 'days'));

		checkProgress();
	}, [
		dateStart,
		checkSubscription,
		checkProgress,
		challenge.schedule,
		challenge.dateStart,
		challenge.days,
	]);

	return {
		progressChecked,
		dateStart,
		dateEnd,
		progressPercent,
		checkSubscription,
		getDay,
		checkChallenge,
		avaliableActivity,
	};
}

export default useSubscription;
