import React from 'react';
import SignFooter from './SignFooter';

import SignLayoutStyle from './SignLayoutStyle';

import LogoFull from 'src/assets/images/logo-full.svg';

const SignForm: React.FC = ({ children }) => {
	return (
		<SignLayoutStyle.Form.Wrapper>
			<SignLayoutStyle.Form.Header>
				<img src={LogoFull} alt='Dasafio Rennovit' />
			</SignLayoutStyle.Form.Header>
			<SignLayoutStyle.Form.Content>
				<div className='container'>
					<SignLayoutStyle.Form.Card>{children}</SignLayoutStyle.Form.Card>
				</div>
			</SignLayoutStyle.Form.Content>
			<SignFooter />
		</SignLayoutStyle.Form.Wrapper>
	);
};

export default SignForm;
