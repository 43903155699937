import styled from '@emotion/styled';

const ChallengeCardsStyle = {
	Wrapper: styled.section`
		margin: 0 -16px;
		overflow: auto;
		position: relative;
		scrollbar-width: none;

		aside {
			padding: 0 0 0 16px;
			white-space: nowrap;
		}

		&::-webkit-scrollbar {
			width: 0;
		}
	`,
	Item: styled.div<{ enabled: boolean }>(
		({ theme, enabled }) => `
        align-items: center;
        background: ${theme.colors.build.light.primary};
        border-radius: ${theme.decoration.border.radius.md};
        color: ${
					enabled
						? theme.colors.build.dark.primary
						: theme.colors.build.gray.g300
				};
        cursor: ${enabled ? 'pointer' : 'auto'};
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 16px;
        padding: 16px 0;
        width: 80px;

        dl {
            margin: 0;
            padding: 0;
            text-align: center;
            dt{
                font-size: 10px;
                line-height: normal;
                text-transform: uppercase;
            }
            dd {
                font-size: 28px;
                font-weight: bold;
                line-height: 1.2;
                margin: 0;
                padding: 0;
            }
        }

        i {
            font-size: 40px;
            line-height: 1;
        }

        &.active {
            background: ${theme.colors.gradient.g002};
            color: ${theme.colors.build.dark.primary};
        }
    `
	),
};

export default ChallengeCardsStyle;
