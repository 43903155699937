import styled from '@emotion/styled';

import bg from 'src/assets/images/background-white.jpg';

const CountDownStyle = {
	Wrapper: styled.div<{ isDashboard?: boolean }>(({ isDashboard }) => {
		if (isDashboard) {
			return `
                position: relative;
                padding-bottom: 40px;
            `;
		}

		return `
            background: url(${bg}) no-repeat center top;
            background-size: 100% auto;
            position: relative;
            min-height: inherit;
        `;
	}),

	Title: styled.div`
		margin: 0 auto 56px;
		max-width: 200px;
		position: relative;
		width: 100%;
	`,

	Content: styled.h2(
		({ theme }) => `
        ${theme.typography.title.t3}
        color: ${theme.colors.build.dark.primary};
        font-style: italic;
        font-weight: 900;
        margin: 0 auto;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    `
	),

	CountDown: styled.div`
		display: flex;
		margin: 16px 0 40px;
		justify-content: center;
	`,

	CountDownItem: styled.div(
		({ theme }) => `
        ${theme.typography.body.short}
        
        margin: 0 4px;
        text-align: center;
        text-transform: uppercase;
        font-style: italic;

        strong {
            ${theme.typography.title.t2}
            align-items: center;
            background: ${theme.colors.gradient.g002};
            border-radius: ${theme.decoration.border.radius.md};
            font-weight: 900;
            display: flex;
            justify-content: center;
            margin-bottom: 4px;
            padding: 8px 0;
            width: 70px;
        }
    `
	),

	Footer: styled.div(
		({ theme }) => `
        text-align: center;
        p{
            ${theme.typography.body.standard}
            margin: 0 auto 16px;
            max-width: 220px;
        }
    `
	),

	ShoppingList: styled.div`
		margin: 0 auto;
		max-width: 430px;
		width: 100%;
	`,
};

export default CountDownStyle;
