import styled from '@emotion/styled';

const HeaderPagesStyle = {
	Wrapper: styled.div(
		({ theme }) => `
        background: ${theme.colors.gradient.g002};
        padding-bottom: 24px;
        `
	),

	Title: styled.div(
		({ theme }) => `
    
            ${theme.typography.title.t3}
            font-style: italic;
            font-weight: 900;
            margin: 0;
            padding: 24px 0 0;
            text-align: center;
            text-transform: uppercase;

            span {
                display: inline-block;
                padding: 0 48px 24px;
                position: relative;

                &:before {
                    background: ${theme.colors.build.light.primary};
                    border-radius: 4px;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 4px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }
        
    `
	),
};

export default HeaderPagesStyle;
