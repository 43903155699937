import { ISubscription } from 'src/interfaces/SubscriptionInterface';

const DEFAULT_VALUE = {
	weightStart: 0,
	weightEnd: 0,
	abdomenStart: 0,
	abdomenEnd: 0,
	hipStart: 0,
	hipEnd: 0,
	progressStatus: 0,
};

const SubscriptionReducer: (
	state: ISubscription,
	action: {
		type:
			| 'SUBSCRIPTION_UPDATE'
			| 'SUBSCRIPTION'
			| 'SUBSCRIPTION_RESET'
			| 'SUBSCRIPTION_NEW';
		value: ISubscription;
	}
) => ISubscription = (state = DEFAULT_VALUE, action) => {
	switch (action.type) {
		case 'SUBSCRIPTION_UPDATE':
			return { ...state, ...action.value };

		case 'SUBSCRIPTION_NEW':
			return { ...action.value };

		case 'SUBSCRIPTION_RESET':
			return { ...DEFAULT_VALUE, ...action.value };

		default:
			return state;
	}
};

export default SubscriptionReducer;
