import styled from '@emotion/styled';

const MainLayoutStyle = {
	Wrapper: styled.main(
		({ theme }) => `
            background: ${theme.colors.build.light.primary};
            box-shadow: ${theme.decoration.shadow.default};
            margin: 0 auto;
            max-width: 560px;
            min-height: 100vh;
            position: relative;

            @media (max-width: 992px) {
                min-height: ${theme.decoration.sizes.body};
            }
        `
	),
};

export default MainLayoutStyle;
