import styled from '@emotion/styled';

import WaterIcon from 'src/assets/images/water-icon.png';

import { WaterCup } from 'src/components/atoms';
import { useRedux } from 'src/redux';

const Wrapper = styled.div();

const Header = styled.div(
	({ theme }) => `
  border-bottom: ${theme.colors.build.gray.g200} solid 1px;
  margin: 0 0 40px;
  padding: 0 0 32px;
  figure {
    margin: 0;
    padding: 0 8px 0 0;
  }

  dl {
    margin: 0;
    padding: 0;

    dt {
      ${theme.typography.title.t4}
      font-style: italic;
      text-transform: uppercase;
    }

    dd {
      ${theme.typography.body.short}

      strong {
        display: inline;
        white-space: nowrap;
      }
    }
  }
`
);

const Info = styled.div(
	({ theme }) => `
  
  h2 {
    ${theme.typography.title.t6}
    margin: 0 0 8px;
    text-align: center;
  }

  h3 {
    ${theme.typography.title.t3}
    background: linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.tertiary});
    border-radius: ${theme.decoration.border.radius.xl};
    margin: 0 0 8px;
    padding: 8px 0;
    line-height: normal;
    text-align: center;
  }

  p {
    ${theme.typography.body.short}
    margin: 0;
    padding: 0 24px;
    text-align: center;
  }
`
);

export const WaterMeter = ({ day = 0 }) => {
	const { subscription } = useRedux();

	const total =
		subscription.schedule && subscription.schedule[day]
			? subscription.schedule[day].water?.total || 0
			: 0;

	const totalWater = (subscription.weightStart * 35) / 1000;

	const totalWaterString = totalWater.toFixed(2).toString().replace('.', ',');

	const countWater = () => {
		if (total) {
			if (total < 1000) {
				return `${total} ml`;
			} else {
				return `${(total / 1000)
					.toFixed(2)
					.toString()
					.replace('.', ',')} litros`;
			}
		}
		return '0 ml';
	};

	const countRestWater = () => {
		let waterCount = total;

		waterCount = totalWater - waterCount / 1000;

		if (waterCount > totalWater) {
			waterCount = totalWater;
		}

		if (waterCount > 0) {
			return waterCount.toFixed(2).toString().replace('.', ',');
		}

		return 'nenhum';
	};

	const waterPercent = () => {
		const percent = ((total / 1000) * 100) / totalWater;
		return percent < 100 ? percent : 100;
	};

	return (
		<Wrapper>
			<Header>
				<div className='row align-items-center'>
					<div className='col-3'>
						<figure>
							<img src={WaterIcon} alt='Água' />
						</figure>
					</div>
					<div className='col-9'>
						<dl>
							<dt>Água</dt>
							<dd>
								Conforme seu peso você precisa tomar{' '}
								<strong>{totalWaterString} litros</strong> de água por dia.
							</dd>
						</dl>
					</div>
				</div>
			</Header>
			<div className='row align-items-center'>
				<div className='col-7'>
					<Info>
						<h2>hoje você já bebeu</h2>
						<h3>{countWater()}</h3>
						<p>
							faltam {countRestWater()} litros de água para atingir a média de
							diária de consumo.
						</p>
					</Info>
				</div>
				<div className='col-5'>
					<WaterCup value={waterPercent()} />
				</div>
			</div>
		</Wrapper>
	);
};
