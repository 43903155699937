import styled from '@emotion/styled';

export const NotificationsStyle = {
	Wrapper: styled.div(
		({ theme }) => `
    border-radius: ${theme.decoration.border.radius.md};
    box-shadow: ${theme.decoration.shadow.default};
    overflow: hidden;
    position: relative;
    `
	),
};
