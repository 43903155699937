import styled from '@emotion/styled';

const LoadingMainStyle = {
	Wrapper: styled.div`
		align-items: center;
		background: #fff;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99999;
		flex-direction: column;

		span {
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
		}
	`,
};

export default LoadingMainStyle;
