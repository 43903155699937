import styled from '@emotion/styled';

import bg from 'src/assets/images/background-green.jpg';

const ResultChallengeStyle = {
	Wrapper: styled.div<{ isDownload: boolean }>(({ isDownload }) => ({
		alignItems: 'center',
		background: `url(${bg}) no-repeat center top`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		margin: isDownload ? '0' : '-56px 0',
		minHeight: isDownload ? '920px' : 'inherit',
		padding: isDownload ? '40px 0 56px' : '80px 0 96px',
		position: 'relative',
		width: '100%',
	})),

	Logo: styled.div({
		margin: '0 auto 40px',
		maxWidth: '100px',
		width: '100%',
	}),

	Title: styled.h3(
		({ theme }) => `
        ${theme.typography.title.t4}
        font-style: italic;
        line-height: normal;
        margin: 0 0 40px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        span {
            position: relative;
            z-index: 3;
        }

        &:before {
            background: ${theme.colors.build.light.primary};
            content: ' ';
            display: block;
            height: 12px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            bottom: -2px;
            width: 250px;
        }
    `
	),

	Results: styled.div(
		({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 280px;
        text-align: center;

        dl {
            margin: 0 0 24px;
            width: 50%;

            dt {
                ${theme.typography.body.standard}
                line-height: 1;
                img {
                    display: block;
                    height: 40px;
                    margin: 0 auto 8px;
                    width: auto;
                }
            }

            dd {
                ${theme.typography.title.t1}
                font-style: italic;
                line-height: 1;
            }
        }
    `
	),

	Hashtag: styled.div(({ theme }) => ({
		color: theme.colors.build.dark.primary,
		fontSize: 24,
		textAlign: 'center',
	})),

	Preview: styled.div(({ theme }) => ({
		display: 'flex',
		margin: '0 -8px 32px',

		dl: {
			margin: 0,
			padding: '0 8px',
			width: '50%',

			dt: {
				fontSize: 16,
				fontWeight: 'bold',
				textAlign: 'center',
				textTransform: 'uppercase',
			},

			dd: {
				margin: 0,
				figure: {
					border: '2px solid #fff',
					borderRadius: theme.decoration.border.radius.sm,
					margin: 0,
					overflow: 'hidden',
				},
			},
		},
	})),
};

export default ResultChallengeStyle;
