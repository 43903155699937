export const theme = {
	colors: {
		primary: '#01fffe',
		secondary: '#A9EE56',
		tertiary: '#fee501',
		action: {
			success: '#219653',
			warning: '#F2994A',
			darger: '#EB5757',
			info: '#2F80ED',
			highlight: '#9B51E0',
		},
		build: {
			dark: {
				primary: '#000',
			},
			light: {
				primary: '#fff',
			},
			gray: {
				g000: '#E5E5E5',
				g100: '#F2F2F2',
				g200: '#E0E0E0',
				g300: '#BDBDBD',
				g400: '#4F4F4F',
				g500: '#828282',
				g600: '#333333',
			},
			brand: {
				b001: '#47f8b8',
				b002: '#bfec40',
				b003: '#57f6a8',
				b004: '#dee821',
				b005: '#d7d9d6',
			},
		},
		gradient: {
			g001: 'linear-gradient(180deg, #fee501, #01fffe)',
			g002: 'linear-gradient(255deg, #47f8b8, #bfec40)',
			g003: 'linear-gradient(90deg, #fee501, #01fffe)',
		},
	},
	decoration: {
		border: {
			radius: {
				none: '0',
				sm: '4px',
				md: '8px',
				lg: '16px',
				xl: '24px',
				full: '100%',
			},
		},
		shadow: {
			default: '0 2px 8px rgba(0, 0, 0, 0.14)',
		},
		sizes: {
			body: `calc(var(--vh, 1vh) * 100)`,
		},
	},
	typography: {
		title: {
			t1: `
				font-size: 40px;
				font-weight: 900;
				line-height: 1.2;
			`,
			t2: `
				font-size: 32px;
				font-weight: bold;
				line-height: 1.4;
			`,

			t3: `
				font-size: 24px;
				font-weight: bold;
				line-height: 1.4;
			`,

			t4: `
				font-size: 20px;
				font-weight: bold;
				line-height: 1.6;
			`,

			t5: `
				font-size: 18px;
				font-weight: bold;
				line-height: normal;
			`,

			t6: `
				font-size: 16px;
				font-weight: 900;
				line-height: normal;
			`,
		},
		body: {
			standard: `
				font-size: 16px;
				font-weight: normal;
				line-height: 1.4;
			`,

			short: `
				font-size: 12px;
				font-weight: normal;
				line-height: 1.6;
			`,
		},
	},
};
