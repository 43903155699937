import React from 'react';
import { useSelector } from 'react-redux';

import { IReducer } from 'src/redux';

import LoadingMainStyle from './LoadingMainStyle';

import running from 'src/assets/images/running.gif';

export const LoadingMain: React.FC = () => {
	const misc = useSelector((state: IReducer) => state.misc);

	if (!misc.loading) {
		return <></>;
	}

	return (
		<LoadingMainStyle.Wrapper>
			<img src={running} alt='Carregando' />
			<span>carregando</span>
		</LoadingMainStyle.Wrapper>
	);
};
