import styled from '@emotion/styled';

const HighlightHeaderStyle = {
	Wrapper: styled.div(
		({ theme }) => `
            background: ${theme.colors.build.light.primary};
            border-radius: ${theme.decoration.border.radius.md};
            box-shadow: ${theme.decoration.shadow.default};
            color: ${theme.colors.build.dark.primary};
            margin: 0 auto;
            max-width: 400px;
            padding: 16px 24px;
            text-align: center;

            h2 {
                ${theme.typography.title.t6}
                color: ${theme.colors.build.dark.primary};
                font-weight: 900;
                margin: 0 0 4px;
                text-transform: uppercase;
            }

            p {
                ${theme.typography.body.short}
                margin: 0;
            }
        `
	),
};

export default HighlightHeaderStyle;
