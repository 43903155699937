import React from 'react';

import { Player } from 'video-react';

import moment from 'moment';

import useSubscription from 'src/hooks/useSubscription';

import { useRedux } from 'src/redux';

import { Button } from 'src/components/atoms';

import {
	ResultChallenge,
	Notifications,
	CountDown,
	PhotoFinished,
} from 'src/components/molecules';

import { HeaderPrivate, NpsChallenge } from 'src/components/organisms';

import ActiveHighlight from 'src/components/common/ActiveHighlight';
import ChallengeCards from 'src/components/common/ChallengeCards';
import ChallengeProgress from 'src/components/common/ChallengeProgress';
import CompleteTasks from 'src/components/common/CompleteTasks';

const emailsHomolog = [
	'negrelis@gmail.com',
	'caetano@janrose.com.br',
	'joaojr@my.com',
	'desafiorennovit@janrose.com.br',
];

const Dashboard: React.FC = () => {
	const { progressChecked, dateEnd, dateStart } = useSubscription();

	const { challenge, subscription, user } = useRedux();

	const hasInChallenge = (): boolean => {
		if (user.role === 'admin' || emailsHomolog.includes(user.email as string)) {
			return progressChecked !== challenge.days;
		} else if (dateEnd.isSameOrBefore(moment(), 'day')) {
			return false;
		} else if (progressChecked === challenge.days) {
			return false;
		}

		return true;
	};

	const checkCountDown = () => {
		return dateStart && moment().isBefore(dateStart);
	};

	const resultComponent = () => {
		if (subscription.weightEnd) {
			if (subscription.npsComplete) {
				return <ResultChallenge />;
			}

			if (!subscription.photoEnd) {
				return <PhotoFinished />;
			}

			return <NpsChallenge />;
		} else {
			return <CompleteTasks />;
		}
	};

	if (checkCountDown()) {
		return <CountDown isDashboard />;
	}

	if (!challenge.days) return null;

	return (
		<>
			<HeaderPrivate />

			{hasInChallenge() ? (
				<div className='mt-3 mb-3'>
					<div className='container'>
						{challenge.videoCover &&
						moment(challenge.dateStart).isSame(moment(), 'day') ? (
							<div
								className='mb-4'
								style={{ borderRadius: '8px', overflow: 'hidden' }}>
								<Player playsInline src={challenge.videoCover} />
							</div>
						) : (
							<></>
						)}

						<Notifications />

						<ChallengeProgress />

						<div className='mt-4 mb-3'>
							<ChallengeCards />
						</div>

						<div className='mb-4'>
							<ActiveHighlight />
						</div>

						<div className='mb-4'>
							<div className='row justify-content-center no-gutters'>
								<div className='col-6 col-md-4 col-lg-3'>
									<Button
										size='small'
										color='secondary'
										container
										onClick={() => window.location.reload()}>
										Atualizar
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				resultComponent()
			)}
		</>
	);
};

export default Dashboard;
