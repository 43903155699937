import React, { useEffect } from 'react';

import { isMobile } from 'react-device-detect';

import { LoadingMain } from 'src/components/atoms';

import { BlockDesktop } from 'src/components/molecules';

import MainLayoutStyle from './MainLayoutStyle';

const MainLayout: React.FC = ({ children }) => {
	useEffect(() => {
		const appHeight = () => {
			const doc = document.documentElement;
			doc.style.setProperty('--vh', window.outerHeight * 0.01 + 'px');
		};

		window.addEventListener('resize', appHeight);

		appHeight();
	}, []);

	if (!isMobile) {
		return <BlockDesktop />;
	}

	return (
		<MainLayoutStyle.Wrapper>
			{children}
			<LoadingMain />
		</MainLayoutStyle.Wrapper>
	);
};

export default MainLayout;
