import moment from 'moment';

export const Helpers = {
	checkEnvDeploy: (): boolean =>
		['production', 'test'].includes(process.env.NODE_ENV),

	pad: (n: number | string, width: number, z: string = '0') => {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	},

	activitieNumber: (dateStart: string | undefined): number => {
		const given = moment().startOf('day');
		const current = moment(dateStart).startOf('day');

		return moment.duration(given.diff(current)).asDays() + 1;
	},
	startEvent: '05/02/2022',
};
