import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';

import { MainService, AuthService } from 'src/services';

import OnBoardingLayoutStyle from './OnBoardingLayoutStyle';

const OnBoardingLayout: React.FC<{ isDashboard?: boolean }> = ({
	children,
	isDashboard,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const checkChallenge = async () => {
		const res = await MainService.fetch('subscription/check');

		if (res.status === 'success') {
			if (res.data.challengeStart) {
				navigate('/app/dashboard');
			} else {
				dispatch({ type: 'SUBSCRIPTION_UPDATE', value: res.data });
			}
		} else {
			message.error('Erro ao acessar seu desafio, por favor tente mais tarde!');
			AuthService.logout();
		}
	};

	const getUser = async () => {
		checkChallenge();

		const res = await MainService.fetch('users/profile');

		if (res.status === 'success') {
			dispatch({ type: 'USER_UPDATE', value: res.data });
		} else {
			message.error('Erro ao acessar seu perfil, por favor tente mais tarde!');
			AuthService.logout();
		}
	};

	useEffect(() => {
		if (!isDashboard) {
			getUser();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<OnBoardingLayoutStyle.Wrapper>{children}</OnBoardingLayoutStyle.Wrapper>
	);
};

export default OnBoardingLayout;
