import { Global, css, useTheme } from '@emotion/react';

import {
	EffraWoff,
	EffraWoff2,
	EffraBoldWoff,
	EffraBoldWoff2,
	EffraHeavyWoff,
	EffraHeavyWoff2,
} from 'src/assets/fonts';

const GlobalStyles = () => {
	const theme = useTheme();

	return (
		<Global
			styles={css`
				@font-face {
					font-family: 'Effra';
					src: url(${EffraWoff2}) format('woff2'),
						url(${EffraWoff}) format('woff');
					font-weight: normal;
					font-style: normal;
				}

				@font-face {
					font-family: 'Effra';
					src: url(${EffraBoldWoff2}) format('woff2'),
						url(${EffraBoldWoff}) format('woff');
					font-weight: bold;
					font-style: normal;
				}

				@font-face {
					font-family: 'Effra';
					src: url(${EffraHeavyWoff2}) format('woff2'),
						url(${EffraHeavyWoff}) format('woff');
					font-weight: 900;
					font-style: normal;
				}

				:root {
					--animate-duration: 200ms;
				}

				html {
					height: -webkit-fill-available;
				}

				body {
					background-image: linear-gradient(
						90deg,
						${theme.colors.tertiary} 0%,
						${theme.colors.primary} 100%
					);
					color: ${theme.colors.build.dark.primary};
					font-family: 'Effra', sans-serif;
					min-height: -webkit-fill-available;
				}

				img {
					max-width: 100%;
				}

				/* Form Ant Design */
				.ant-form-item {
					.ant-input {
						border-color: ${theme.colors.build.dark.primary};
						border-radius: ${theme.decoration.border.radius.md};
						height: 52px;
						line-height: normal;
						padding: 0 14px;
					}

					textarea.ant-input {
						height: auto;
						resize: none;
					}

					.ant-input-affix-wrapper {
						border-color: ${theme.colors.build.dark.primary};
						border-radius: ${theme.decoration.border.radius.md};
						padding-bottom: 0;
						padding-top: 0;
					}

					&-label {
						font-style: normal;
						font-weight: bold;
					}

					&-explain {
						line-height: normal;
						font-size: 10px;
						font-weight: normal;
						padding: 2px 0 0;
						&.ant-form-item-explain-error {
							color: ${theme.colors.action.darger};
						}
					}

					&-children-icon {
						align-items: center;
						display: flex;
					}
				}

				.ant-steps-item-icon {
					align-items: center;
					display: inline-flex;
					justify-content: center;
				}

				.ant-steps-item-finish .ant-steps-item-icon,
				.ant-steps-item-process .ant-steps-item-icon {
					border-color: ${theme.colors.action.success};
				}

				.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
					color: ${theme.colors.action.success};
				}

				.ant-steps-item-finish
					> .ant-steps-item-container
					> .ant-steps-item-content
					> .ant-steps-item-title::after,
				.ant-steps-item-process
					> .ant-steps-item-container
					> .ant-steps-item-icon {
					background: ${theme.colors.action.success};
				}

				.ant-radio-button-wrapper {
					align-items: center;
					display: inline-flex;
					height: 48px;
					line-height: normal;

					&-checked:not(.ant-radio-button-wrapper-disabled) {
						color: ${theme.colors.action.success};
						border-color: ${theme.colors.action.success};

						&:first-of-type,
						&:active,
						&:hover {
							color: ${theme.colors.action.success};
							border-color: ${theme.colors.action.success};
						}

						&:before {
							background-color: ${theme.colors.action.success};
						}
					}
				}

				.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-of-type {
					border-right-color: ${theme.colors.action.success};
				}

				.ant-drawer-title {
					${theme.typography.title.t4}
					line-height: normal;
				}

				.video-react {
					.video-react-big-play-button {
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			`}
		/>
	);
};

export default GlobalStyles;
