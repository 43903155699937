import styled from '@emotion/styled';
import { message, Spin } from 'antd';
import { Form, Input } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MainService } from 'src/services/MainServices';
import { Button } from '../atoms';

const Header = styled.dl({
	margin: '0 0 24px',
	padding: 0,

	dt: {
		fontSize: 20,
		fontWeight: 'bold',
		margin: 0,
		padding: 0,
		textTransform: 'uppercase',
	},

	dd: {
		margin: 0,
		padding: 0,
	},
});

export const SignupChallengeForm = () => {
	const dispatch = useDispatch();

	const [form] = Form.useForm();

	const [isLoading, setLoading] = useState(false);

	const onFinish = async (values: { idOrderProduct: string }) => {
		setLoading(true);

		const result = await MainService.fetch('mmn/validate', 'GET', null, {
			idProduct: values.idOrderProduct,
		});

		setLoading(false);

		if (result.status === 'success') {
			if (result.product.avaliable) {
				dispatch({
					type: 'UPDATE_TOKEN_SIGN_UP',
					value: result.token,
				});

				message.success('Kit validado');
			} else {
				if (!result.product.avaliable) {
					let content = 'Acesso ao desafio não está disponível para cadastro';

					if (!result.product.payConfirm) {
						content = 'Ainda não foi confirmado o pagamento deste pedido';
					}

					message.error(content);
				}
			}
		} else {
			let content;

			if (result.data.message) {
				content = result.data.message;
			} else if (result.error) {
				content = result.error;
			} else {
				content = 'Erro na aplicação, entre em contato com nossa equipe.';
			}

			message.error(content);
		}
	};

	return (
		<div className='container'>
			<Spin spinning={isLoading}>
				<Header>
					<dt>Iniciar Cadastro</dt>
					<dd>
						Para continuar seu cadastro, insira o número do pedido com o{' '}
						<strong>Kit Rennovit Detox Week</strong>.
					</dd>
				</Header>
				<Form
					layout='vertical'
					form={form}
					onFinish={onFinish}
					data-testid='form-code'>
					<Form.Item
						label='Pedido do Kit'
						name='idOrderProduct'
						extra='Informe o pedido que contém o kit'
						rules={[
							{ required: true, message: 'Digite um código válido' },
							{
								min: 5,
								message: 'Código inválido',
							},
						]}>
						<Input
							placeholder='Apenas número'
							disabled={isLoading}
							data-testid='input-product'
						/>
					</Form.Item>

					<Button container disabled={isLoading}>
						Continuar
					</Button>
				</Form>
			</Spin>
		</div>
	);
};
