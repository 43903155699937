import styled from '@emotion/styled';

const OnBoardingPaginateStyle = {
	Wrapper: styled.div(
		({ theme }) => `
        bottom: 32px;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        width: 100%;

        span {
            background: ${theme.colors.build.gray.g300};
            border-radius: ${theme.decoration.border.radius.full};
            display: inline-flex;
            height: 8px;
            margin: 0 8px;
            transition: all 0.3s ease-in-out;
            width: 8px;

            &.active {
                background: ${theme.colors.secondary};
            }
        }
    `
	),
};

export default OnBoardingPaginateStyle;
