import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MainService } from 'src/services/MainServices';

import { useRedux } from 'src/redux';

import OnBoardingLayout from 'src/layout/OnBoardingLayout';

import OnBoardingPaginate from './OnBoardingPaginate';

import OnBoardingWeight from './OnBoardingWeight';
import OnBoardingStart from './OnBoardingStart';
import { Modal } from 'antd';
import { OnBoardingPhoto } from './OnBoardingPhoto';

const OnBoarding: React.FC<{ isDashboard?: boolean }> = ({ isDashboard }) => {
	const navigate = useNavigate();

	const { user, subscription } = useRedux();
	const dispatch = useDispatch();

	const [step, setStep] = useState(0);

	const startChallenge = async (): Promise<void> => {
		dispatch({ type: 'LOADING_SHOW' });

		MainService.fetch('users/profile', 'post', {
			_id: user._id,
			name: user.name,
			email: user.email,
			phone: user.phone,
			sex: user.sex,
		});

		const res = await MainService.fetch(
			`subscription/onboarding/${subscription._id}`,
			'patch',
			{
				weightStart: subscription.weightStart,
				abdomenStart: subscription.abdomenStart,
				hipStart: subscription.hipStart,
				photoStart: subscription.photoStart,
				challengeStart: Date.now(),
			}
		);

		if (res.status === 'success') {
			if (isDashboard) {
				window.location.reload();
			} else {
				navigate('app/dashboard');
			}
		} else {
			Modal.error({
				title: 'Iniciar desafio',
				content:
					'Não foi possível iniciar o desafio, tente novamente mais tarde',
			});
		}

		dispatch({ type: 'LOADING_HIDE' });
	};

	const nextStep = () => {
		setStep(step + 1);
	};

	const prevStep = () => {
		setStep(step - 1);
	};

	const Steps = (): JSX.Element => {
		switch (step) {
			case 1:
				return <OnBoardingPhoto backStep={prevStep} nextStep={nextStep} />;
			case 2:
				return <OnBoardingStart nextStep={() => startChallenge()} />;

			default:
				return <OnBoardingWeight nextStep={nextStep} />;
		}
	};

	return (
		<OnBoardingLayout isDashboard={isDashboard}>
			<Steps />
			<OnBoardingPaginate paged={step} total={3} />
		</OnBoardingLayout>
	);
};

export default OnBoarding;
