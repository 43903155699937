import React from 'react';

import ShoppingListStyle from './ShoppingListStyle';

import HeaderPages from 'src/components/common/HeaderPages';
import HighlightHeader from 'src/components/common/HighlightHeader';
import ShoppingListCards from 'src/components/common/ShoppingListCards';

const ShoppingList: React.FC = () => {
	return (
		<>
			<ShoppingListStyle.Header>
				<HeaderPages title='Lista de Compras'>
					<ShoppingListStyle.Info>
						<div className='container'>
							<article>
								<HighlightHeader title='IMPORTANTE:'>
									Antes de ir as compras, verifique os itens que você já tem na
									sua dispensa ou geladeira, reaproveite alimentos, os itens
									permitidos e utlizados serão os que estão neste lista.{' '}
									<strong>Prepare-se!</strong>
								</HighlightHeader>
							</article>
						</div>
					</ShoppingListStyle.Info>
				</HeaderPages>
			</ShoppingListStyle.Header>

			<ShoppingListCards />
		</>
	);
};

export default ShoppingList;
