import React from 'react';
import { HeaderPrivate } from 'src/components/organisms';

const Challenge: React.FC = () => {
	return (
		<div>
			<HeaderPrivate back>Desafio</HeaderPrivate>
			desafio
		</div>
	);
};

export default Challenge;
