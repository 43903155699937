import React from 'react';

import styled from '@emotion/styled';

import { NpsQuestions } from 'src/components/molecules';

import bg from 'src/assets/images/background-green.jpg';

const Wrapper = styled.div`
	background: url(${bg}) no-repeat center top;
	background-size: cover;
	padding: 40px 24px;
	min-height: calc(100vh - 112px);
	position: relative;
	width: 100%;
`;

const Title = styled.h1(
	({ theme }) => `
	color: ${theme.colors.build.dark.primary};
	font-size: 16px;
	font-style: italic;
	font-weight: bold;
	line-height: normal;
	margin-bottom: 24px;
	text-align: center;
	text-transform: uppercase;

	span {
		display: inline-block;
		margin-bottom: 2px;
		padding: 0 8px;
		position: relative;

		&:before {
			background: ${theme.colors.build.light.primary};
			bottom: -12%;
			content: ' ';
			display: block;
			height: 70%;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	em {
		position: relative;
		z-index: 2;
	}
`
);

const Description = styled.div(
	({ theme }) => `
		${theme.typography.body.short}
		font-style: italic;
		text-transform: uppercase;
		text-align: center;
		margin: 0 auto 40px;
		max-width: 240px;
`
);

export const NpsChallenge: React.FC = () => {
	return (
		<Wrapper>
			<Title>
				<span>
					<em>Parabéns, você chegou ao final</em>
				</span>
				<span>
					<em>da Detox Week</em>
				</span>
			</Title>

			<Description>
				Agora conte pra gente como foi sua experiência com este desafio? <br />
				#compartilhe
			</Description>

			<NpsQuestions />
		</Wrapper>
	);
};
