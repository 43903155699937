import React from 'react';

import styled from '@emotion/styled';

import { ButtonAction } from '../atoms/ButtonAction';
import { IconWater1, IconWater2, IconWater3, IconWater4 } from '../atoms';
import { useRedux } from 'src/redux';
import { useDispatch } from 'react-redux';
import { MainService } from 'src/services/MainServices';

type TSize = 300 | 500 | 750 | 1000;

const Wrapper = styled.div<{ size: TSize }>(({ theme, size }) => {
	let sizeEnd = 0;

	switch (size) {
		case 300:
			sizeEnd = 20;
			break;

		case 500:
			sizeEnd = 16;
			break;

		case 750:
			sizeEnd = 24;
			break;

		case 1000:
			sizeEnd = 56;
			break;

		default:
			sizeEnd = 32;
			break;
	}
	return `
  	padding: 8px;
  
    dl {
      margin: 0;
      padding: 0;
      text-align: center;
  
      dt {
        align-items: flex-end;
        display: flex;
        height: 64px;
        justify-content: center;
        margin: 0 0 4px;
  
        svg {
          
          height: auto;
          width: ${sizeEnd}px;
  
          path {
            fill: ${theme.colors.build.brand.b001};
          }
        }
      }
  
      dd {
        ${theme.typography.body.short}
        font-weight: bold;
        margin: 0;
        padding: 0;
        text-align: center;
        white-space: nowrap;
      }
    }
  `;
});

export const WaterActions: React.FC<{
	size: TSize;
	day: number;
}> = ({ size, day = 0 }) => {
	const unit = size < 1000 ? 'ml' : 'litro';

	const { subscription } = useRedux();

	const dispatch = useDispatch();

	const Icon = () => {
		switch (size) {
			case 300:
				return <IconWater1 />;

			case 500:
				return <IconWater2 />;

			case 750:
				return <IconWater3 />;

			case 1000:
				return <IconWater4 />;

			default:
				return <IconWater1 />;
		}
	};

	const action = (format: 'increment' | 'decrement') => {
		const schedule = subscription.schedule || [];

		const total =
			schedule[day] && schedule[day].water
				? schedule[day].water?.total || 0
				: 0;

		let value = size as number;

		if (format === 'increment') {
			value = size + total;
		} else {
			const decrement = total - size;

			value = decrement > 0 ? decrement : 0;
		}

		if (schedule[day]) {
			schedule[day].water = {
				total: value,
			};
		} else {
			schedule[day] = {
				water: {
					total: value,
				},
			} as any;
		}

		subscription.schedule = schedule as any;

		dispatch({
			type: 'SUBSCRIPTION_UPDATE',
			value: subscription,
		});

		MainService.fetch(`subscription/onboarding/${subscription._id}`, 'patch', {
			...subscription,
		});
	};

	return (
		<Wrapper size={size}>
			<div className='row align-items-center g-0'>
				<div className='col-auto'>
					<ButtonAction format='minus' onClick={() => action('decrement')} />
				</div>
				<div className='col'>
					<dl>
						<dt>
							<Icon />
						</dt>
						<dd>{`${size > 1000 ? size / 1000 : size} ${unit}`}</dd>
					</dl>
				</div>
				<div className='col-auto'>
					<ButtonAction format='plus' onClick={() => action('increment')} />
				</div>
			</div>
		</Wrapper>
	);
};
