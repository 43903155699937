import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, Input, message, Modal } from 'antd';

import decode from 'jwt-decode';

import { Mixpanel } from 'src/services/Mixpanel';

import { MainService } from 'src/services/MainServices';

import SignStyle from './SignStyle';

import SignForm from 'src/layout/SignLayout/SignForm';

import { Button } from 'src/components/atoms';

import checked from 'src/assets/images/check-gradient.png';

const SignUpdatePass: React.FC = () => {
	const [form] = Form.useForm();

	const [loading, setLoading] = useState(false);

	const [confirm, setConfirm] = useState(false);

	const navigate = useNavigate();
	const urlParams = useParams();

	const handleLogin = () => {
		navigate('/');
	};

	const onFinish = async (values: {
		email: string;
		password: string;
		token: string;
	}): Promise<void> => {
		setLoading(true);

		const res = await MainService.fetch('users/pass/update', 'post', {
			email: values.email,
			password: values.password,
			token: urlParams.token,
		});

		Mixpanel.track('Esqueci minha senha - Atualizar', {
			status: res.status,
			email: values.email,
		});

		if (res.status === 'success') {
			message.success(res.data.message);
			setConfirm(true);
		} else if (res.status === 'error') {
			Modal.warning({
				title: 'ATUALIZAR SENHA',
				content: res.data.message,
			});
		} else {
			message.error(
				'Estamos com problema em nossos servidores, tente novamente mais tarde.'
			);
		}

		setLoading(false);
	};

	const initUpdatePass = useCallback((): void => {
		const { email, exp } = decode<{ email: string; id: string; exp: number }>(
			urlParams.token as string
		);

		form.setFieldsValue({
			email,
		});

		if (exp < new Date().getTime() / 1000) {
			message.error('Este link foi expirado');
			navigate('/');
		}
	}, [urlParams, navigate, form]);

	useEffect(() => {
		initUpdatePass();
	}, [initUpdatePass]);

	return (
		<SignForm>
			{confirm ? (
				<>
					<SignStyle.ConfirmPass data-testid='confirm-show'>
						<dt>
							<figure>
								<img src={checked} alt='Sucesso' />
							</figure>
							Sucesso
						</dt>
						<dd>Sua senha foi alterada!</dd>
					</SignStyle.ConfirmPass>
					<Button container data-testid='go-login' onClick={handleLogin}>
						Fazer Login
					</Button>
				</>
			) : (
				<>
					<SignStyle.HeroText>
						<dt>Redefinir sua senha</dt>
						<dd>Agora você pode atualizar sua senha.</dd>
					</SignStyle.HeroText>
					<Form
						layout='vertical'
						form={form}
						onFinish={onFinish}
						data-testid='update-pass-form'
						autoComplete='off'>
						<Form.Item label='Email' name='email' hasFeedback>
							<Input
								placeholder='Digite seu Email'
								disabled
								data-testid='input-email'
								readOnly
							/>
						</Form.Item>

						<Form.Item label='Senha' name='password' hasFeedback>
							<Input.Password
								placeholder='Digite sua senha'
								disabled={loading}
								data-testid='input-pass'
							/>
						</Form.Item>

						<div className='pt-2'>
							<Button
								container
								disabled={loading}
								loaded={loading}
								data-testid='atualizar-button'>
								ATUALIZAR SENHA
							</Button>
						</div>
					</Form>
				</>
			)}
		</SignForm>
	);
};

export default SignUpdatePass;
