import React from 'react';

import styled from '@emotion/styled';

import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const Wrapper = styled.button(
	({ theme }) => `
    align-items: center;
    background: ${theme.colors.build.dark.primary};
    border-radius: ${theme.decoration.border.radius.sm};
    border: none;
    color: ${theme.colors.build.light.primary};
    display: flex;
    font-size: 8px;
    height: 18px;
    justify-content: center;
    padding: 0;
    width: 18px;

    span {
      display: block;
    }

`
);

interface IButtonAction extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	format: 'plus' | 'minus';
}

export const ButtonAction: React.FC<IButtonAction> = ({ format, ...props }) => {
	const Icon = () => {
		switch (format) {
			case 'minus':
				return <MinusOutlined />;

			default:
				return <PlusOutlined />;
		}
	};
	return (
		<Wrapper {...props}>
			<Icon />
		</Wrapper>
	);
};
