import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, message, Slider, Spin } from 'antd';

import styled from '@emotion/styled';

import { MainService } from 'src/services/MainServices';

import { Button } from 'src/components/atoms';

import { useTheme } from '@emotion/react';
import { useRedux } from 'src/redux';
import { useDispatch } from 'react-redux';

interface IQuestion {
	title: string;
	_id: string;
}

export const NpsQuestions: React.FC = () => {
	const dispatch = useDispatch();

	const { user, challenge, subscription } = useRedux();

	const [isLoading, setLoading] = useState(false);

	const theme = useTheme();

	const [questions, setQuestions] = useState<IQuestion[]>([]);

	const getQuestions = useCallback(async () => {
		const res = await MainService.fetch('nps-question/challenge');

		if (res.status === 'success') {
			setQuestions(res.data);
		}
	}, []);

	const Wrapper = styled.div`
		.ant-slider {
			&-handle {
				background: ${theme.colors.tertiary};
				border-color: ${theme.colors.build.light.primary};
				height: 24px;
				margin-top: -11px;
				width: 24px;
			}

			&-dot-active {
				background-color: ${theme.colors.build.light.primary};
				border-color: ${theme.colors.build.light.primary};
			}

			&-rail {
				background-color: ${theme.colors.build.light.primary};
			}

			&-track {
				background: ${theme.colors.tertiary};
			}

			&-mark-text {
				${theme.typography.body.standard}
				font-weight: bold;
				padding-top: 6px;
			}

			&-with-marks {
				margin-bottom: 40px;
			}

			&:hover {
				.ant-slider {
					&-track {
						background-color: ${theme.colors.tertiary};
					}

					&-rail {
						background-color: ${theme.colors.build.light.primary};
					}
				}
			}
		}
	`;

	const updateSubscription = async () => {
		await MainService.fetch(
			`subscription/onboarding/${subscription._id}`,
			'patch',
			{
				npsComplete: true,
			}
		);

		dispatch({
			type: 'SUBSCRIPTION_UPDATE',
			value: {
				npsComplete: true,
			},
		});

		setLoading(false);
	};

	const saveNps = async (data) => {
		setLoading(true);

		const res = await MainService.fetch('nps', 'put', data);

		if (res.status === 'success') {
			updateSubscription();
		} else {
			setLoading(false);
			message.error('Erro ao enviar seus dados, tente novamente mais tarde.');
		}
	};

	const onFinish = (values: {
		questions: [
			{
				value;
			}
		];
		testimonial: string;
	}) => {
		let overall = 0;

		values.questions.forEach((item) => {
			overall = overall + item.value;
		});

		const data = {
			idUser: user._id,
			user: user._id,
			idChallenge: challenge._id,
			challenge: challenge._id,
			questions: values.questions,
			testimonial: values.testimonial,
			overall: overall / values.questions.length,
		};

		saveNps(data);
	};

	useEffect(() => {
		getQuestions();
	}, [getQuestions]);

	return (
		<Spin spinning={isLoading}>
			<Wrapper>
				<Form name='nps-question' layout='vertical' onFinish={onFinish}>
					{questions.map((item, index) => (
						<div key={item._id}>
							<Form.Item
								name={['questions', index, 'idQuestion']}
								initialValue={item._id}
								hidden
							/>
							<Form.Item
								name={['questions', index, 'value']}
								label={item.title}>
								<Slider
									min={0}
									max={5}
									marks={{
										0: '0',
										1: '1',
										2: '2',
										3: '3',
										4: '4',
										5: '5',
									}}
								/>
							</Form.Item>
						</div>
					))}

					<Form.Item
						label='Depoimento:'
						name='testimonial'
						rules={[
							{
								required: true,
								message:
									'Digite um depoimento, sua opinião é muito importante para nós.',
							},
						]}>
						<Input.TextArea rows={8} />
					</Form.Item>

					<Button container color='white' disabled={isLoading}>
						ENVIAR
					</Button>
				</Form>
			</Wrapper>
		</Spin>
	);
};
