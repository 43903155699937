import React from 'react';

import styled from '@emotion/styled';

import Cup from 'src/assets/images/water-cup.png';
import WaterImage from 'src/assets/images/water.png';

const Wrapper = styled.div`
	position: relative;
	overflow: hidden;
`;

const Meter = styled.div`
	position: relative;
	z-index: 3;
`;

const Water = styled.div(
	`
    left: 0;
    position: absolute;
    transition: top 0.3s cubic-bezier(0, 0.47, 0.02, 1.33);
    z-index: 1;
  `
);

export const WaterCup: React.FC<{ value: number }> = ({ value }) => {
	return (
		<Wrapper>
			<Meter>
				<img src={Cup} alt='Medida de Água' />
			</Meter>
			<Water
				style={{
					top: `${100 - value > 100 ? 100 : 100 - value}%`,
				}}>
				<img src={WaterImage} alt='Água' />
			</Water>
		</Wrapper>
	);
};
