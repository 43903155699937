import React from 'react';

import styled from '@emotion/styled';

import { Thumbnail } from 'src/components/atoms';
import { useRedux } from 'src/redux';

const Wrapper = styled.div(
	({ theme }) => `
    margin-bottom: 48px;
    text-align: center;

    figure {
      border: 2px solid ${theme.colors.build.light.primary};
      border-radius: ${theme.decoration.border.radius.full};
      height: 120px;
      margin: 0 auto 16px;
      object-fit: cover;
      overflow: hidden;
      width: 120px;
    }

    p {
      ${theme.typography.title.t4}
      color: ${theme.colors.build.dark.primary};
      font-weight: 900;
      margin: 0;
      position: relative;
      text-transform: uppercase;

      &:before {
        background: ${theme.colors.build.dark.primary};
        bottom: -8px;
        content: ' ';
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 50px;
      }
    }
  `
);

export const UserImageName: React.FC = () => {
	const { user } = useRedux();

	return (
		<Wrapper>
			<figure>
				<Thumbnail />
			</figure>

			<p>{user.name}</p>
		</Wrapper>
	);
};
