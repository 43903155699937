import React from 'react';
import { HeaderPrivate } from 'src/components/organisms';

import HeaderPagesStyle from './HeaderPagesStyle';

interface IHeaderPages {
	title: string;
	back?: () => void;
}

const HeaderPages: React.FC<IHeaderPages> = ({ children, title }) => {
	return (
		<HeaderPagesStyle.Wrapper>
			<HeaderPrivate back transparent />
			<HeaderPagesStyle.Title>
				<span>{title}</span>
			</HeaderPagesStyle.Title>
			<div>{children}</div>
		</HeaderPagesStyle.Wrapper>
	);
};

export default HeaderPages;
