import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import OnBoardingWeightStyle from './OnBoardingWeightStyle';

import { HeaderBack, Button } from 'src/components/atoms';

import SizesChallenge from 'src/components/forms/SizesChallenge';

const OnBoardingWeight: React.FC<{
	backStep?: () => void;
	nextStep: () => void;
}> = ({ backStep = () => null, nextStep }) => {
	const dispatch = useDispatch();

	const [weight, setWeight] = useState(50);
	const [abdomen, setAbdomen] = useState(50);
	const [hip, setHip] = useState(50);

	const setting = {
		weight,
		abdomen,
		hip,
		onChange: (value: number, format: string): void => {
			switch (format) {
				case 'weight':
					setWeight(value);
					break;
				case 'abdomen':
					setAbdomen(value);
					break;
				case 'hip':
					setHip(value);
					break;

				default:
					break;
			}
		},
	};

	const nextStepWeight = (): void => {
		dispatch({
			type: 'SUBSCRIPTION_UPDATE',
			value: { weightStart: weight, abdomenStart: abdomen, hipStart: hip },
		});
		nextStep();
	};

	return (
		<OnBoardingWeightStyle.Wrapper>
			<div className='container'>
				<HeaderBack title='Seus dados' back={backStep}>
					Queremos te conhecer melhor! Informe seu peso e medida para que
					possamos organizar algumas coisas:
				</HeaderBack>

				<OnBoardingWeightStyle.Content>
					<SizesChallenge {...setting} />
				</OnBoardingWeightStyle.Content>

				<Button container disabled={weight < 10} onClick={nextStepWeight}>
					Continuar
				</Button>
			</div>
		</OnBoardingWeightStyle.Wrapper>
	);
};

export default OnBoardingWeight;
