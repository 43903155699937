import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import 'moment/locale/pt-br';

import { Mixpanel } from './services/Mixpanel';

import { ThemeProvider } from '@emotion/react';
import GlobalStyles from './styles/GlobalStyles';
import { theme } from './styles/theme';

// Components
import MainLayout from './layout/MainLayout';
import PrivateLayout from './layout/PrivateLayout';

// Pages
import SignErrorInvoice from './pages/sign/SignErrorInvoice';
import SignErrorChallange from './pages/sign/SignErrorChallenge';

import Dashboard from './pages/private/Dashboard';
import Challenge from './pages/private/Challenges/Challenge';
import Activities from './pages/private/Challenges/Activities';
import ShoppingList from './pages/private/ShoppingList';
import Receitas from './pages/private/Receitas';
import ReceitasSingle from './pages/private/Receitas/ReceitasSingle';
import Profile from './pages/private/Profile';

import SignLogin from './pages/sign/SignLogin';
import SignInvoice from './pages/sign/SignInvoice';
import SignPass from './pages/sign/SignPass';
import SignUpdatePass from './pages/sign/SignUpdatePass';

import OnBoarding from './pages/onboarding';

const routesPrivate = [
	{
		key: 'dashboard',
		path: '/app/dashboard',
		exact: true,
		element: (
			<PrivateLayout>
				<Dashboard />
			</PrivateLayout>
		),
	},
	{
		key: 'desafio',
		path: '/app/desafio/:id',
		exact: true,
		element: (
			<PrivateLayout>
				<Activities />
			</PrivateLayout>
		),
	},
	{
		key: 'desafio',
		path: '/app/desafio',
		exact: true,
		element: (
			<PrivateLayout>
				<Challenge />
			</PrivateLayout>
		),
	},
	{
		key: 'shopping-list',
		path: '/app/lista-de-compras',
		exact: true,
		element: (
			<PrivateLayout>
				<ShoppingList />
			</PrivateLayout>
		),
	},
	{
		key: 'receitas-single',
		path: '/app/receitas/:id',
		exact: true,
		element: (
			<PrivateLayout>
				<ReceitasSingle />
			</PrivateLayout>
		),
	},
	{
		key: 'receitas',
		path: '/app/receitas',
		exact: true,
		element: (
			<PrivateLayout>
				<Receitas />
			</PrivateLayout>
		),
	},

	{
		key: 'profile',
		path: '/app/profile',
		exact: true,
		element: (
			<PrivateLayout>
				<Profile />
			</PrivateLayout>
		),
	},
];

const routesOnBoarding = [
	{
		key: 'onboarding',
		path: '/onboarding',
		exact: true,
		element: <OnBoarding />,
	},
];

const routesErrors = [
	{
		key: 'erro-desafio',
		path: '/erro/desafio',
		element: (
			<PrivateLayout>
				<SignErrorChallange />
			</PrivateLayout>
		),
	},
	{
		key: 'erro-pedido',
		path: '/erro/pedido',
		element: (
			<PrivateLayout>
				<SignErrorInvoice />
			</PrivateLayout>
		),
	},
];

const routesPublic = [
	{
		key: 'atualizar-senha-token',
		path: '/atualizar-senha/:token',
		element: <SignUpdatePass />,
	},
	{
		key: 'atualizar-senha',
		path: '/atualizar-senha',
		element: <SignUpdatePass />,
	},
	{
		key: 'esqueceu-senha',
		path: '/esqueceu-senha',
		element: <SignPass />,
	},
	{
		key: 'cadastro',
		path: '/cadastro',
		element: <SignInvoice />,
	},
	{
		key: 'login',
		path: '/',
		exact: true,
		element: <SignLogin />,
	},
];

const App: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
		const url = location.pathname + location.search;

		Mixpanel.track(`Page: ${url}`, {
			url: url,
		});
	}, [location.pathname, location.search]);

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<MainLayout>
				<Routes>
					{routesErrors.map((item) => (
						<Route {...item} />
					))}

					{routesPublic.map((item) => (
						<Route {...item} />
					))}

					{routesOnBoarding.map((item) => (
						<Route {...item} />
					))}

					{routesPrivate.map((item) => (
						<Route {...item} />
					))}
				</Routes>
			</MainLayout>
		</ThemeProvider>
	);
};

export default App;
