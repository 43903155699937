import React from 'react';

import { useNavigate } from 'react-router';

import ArrowLeft from 'src/assets/images/arrow-left-color.png';
import Logo from 'src/assets/images/logo.svg';

import SignFooter from './SignFooter';

import SignLayoutStyle from './SignLayoutStyle';

interface ISignErro {
	title?: string;
	back?: string;
	image?: string;
}

const SignError: React.FC<ISignErro> = ({ children, title, back, image }) => {
	const navigate = useNavigate();

	const goBack = (): void => {
		if (back) {
			navigate(back);
		} else {
			window.history.back();
		}
	};
	return (
		<SignLayoutStyle.Form.Wrapper clean>
			<SignLayoutStyle.Error.Header>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col'>
							<SignLayoutStyle.Error.HeaderButton
								type='button'
								onClick={goBack}>
								<img src={ArrowLeft} alt='Voltar' />
							</SignLayoutStyle.Error.HeaderButton>
						</div>
						<div className='col-auto'>
							<SignLayoutStyle.Error.Logoheader>
								<img src={Logo} alt='Desafio Rennovit' />
							</SignLayoutStyle.Error.Logoheader>
						</div>
					</div>
				</div>
			</SignLayoutStyle.Error.Header>
			<SignLayoutStyle.Form.Content>
				<div className='container'>
					<SignLayoutStyle.Error.Title>{title}</SignLayoutStyle.Error.Title>
					{children}
					{image && (
						<SignLayoutStyle.Error.Image>
							<img src={image} alt={title} />
						</SignLayoutStyle.Error.Image>
					)}
				</div>
			</SignLayoutStyle.Form.Content>
			<SignFooter />
		</SignLayoutStyle.Form.Wrapper>
	);
};

export default SignError;
