import styled from '@emotion/styled';
import React from 'react';

import Logo from 'src/assets/images/logo-desafio.png';

const Wrapper = styled.main`
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 100vh;
	text-align: center;
	padding: 60px 0;

	img {
		height: auto;
		width: 172px;
	}

	aside {
		max-width: 540px;
		padding-top: 60px;
		margin: 0 auto;
		font-size: 20px;
	}
`;

export const BlockDesktop: React.FC = () => {
	return (
		<Wrapper>
			<div className='container'>
				<img src={Logo} alt='Desafio Rennovit' />

				<aside>
					Para uma melhor experiência,{' '}
					<strong>o acesso deste aplicativo</strong> será feito somente através
					de um celular.
					<br />
					<br />
					Obrigado ;)
				</aside>
			</div>
		</Wrapper>
	);
};
