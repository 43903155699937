import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';

import { MainService } from 'src/services/MainServices';

import { NotificationsStyle } from './NotificationsStyle';

export const Notifications: React.FC = () => {
	const [isLoading, setLoading] = useState(false);

	const [data, setData] = useState([]);

	const getNotifications = useCallback(async () => {
		setLoading(true);

		const res = await MainService.fetch('notification');

		setLoading(false);

		if (res.status === 'success') {
			setData(res.data);
		}
	}, []);

	useEffect(() => {
		getNotifications();
	}, [getNotifications]);

	return (
		<Spin spinning={isLoading}>
			{data.map(
				(item: {
					_id: string;
					imageUrl: string;
					title: string;
					url: string;
				}) => (
					<div className='mb-4' key={item._id}>
						<NotificationsStyle.Wrapper>
							{item.url ? (
								<a href={item.url} target='_blank' rel='noreferrer'>
									<img src={item.imageUrl} alt={item.title} />
								</a>
							) : (
								<img src={item.imageUrl} alt={item.title} />
							)}
						</NotificationsStyle.Wrapper>
					</div>
				)
			)}
		</Spin>
	);
};
