import { Mixpanel } from 'src/services/Mixpanel';

export interface IUser {
	_id?: string;
	name?: string;
	email?: string;
	phone?: string;
	imageUrl?: string;
	sex?: string;
	role?: string;
}

const UserReducer: (
	state: IUser,
	action: {
		type: 'USER_UPDATE' | 'USER';
		value: IUser;
	}
) => IUser = (
	state = {
		name: '',
	},
	action
) => {
	if (action.type === 'USER_UPDATE') {
		if (action.value._id) {
			Mixpanel.identify(action.value._id);
			Mixpanel.people({
				$first_name: action.value.name,
				$email: action.value.email,
				$phone: action.value.phone,
				$sex: action.value.phone,
			});
		}
		return { ...state, ...action.value };
	}

	return state;
};

export default UserReducer;
