import styled from '@emotion/styled';

import { IButton } from './';

const ButtonStyle = styled.button<IButton>(
	({ theme, container, color, disabled, loaded, format, size }) => {
		let style = '';

		if (container) {
			style += `width: 100%;`;
		}

		switch (color) {
			case 'primary':
				style += `background: ${theme.colors.primary};`;
				break;

			case 'secondary':
				style += `background: ${theme.colors.secondary};`;
				break;

			case 'tertiary':
				style += `background: ${theme.colors.tertiary};`;
				break;

			case 'white':
				style += `background: ${theme.colors.build.light.primary};`;
				break;

			case 'transparent':
				style += `
                    background: transparent;
                    font-style: normal;
                `;
				break;

			default:
				style += `
                    background: linear-gradient(90deg, #fee501 0%, #01fffe 100%);
                `;
				break;
		}

		if (format === 'ghost') {
			style += `
                background: transparent;
                border: 1px solid transparent;
            `;

			switch (color) {
				case 'primary':
					style += `
                        border-color: ${theme.colors.primary};
                        color: ${theme.colors.primary};
                    `;
					break;

				case 'secondary':
					style += `
                        border-color: ${theme.colors.secondary};
                        color: ${theme.colors.secondary};
                    `;
					break;

				case 'tertiary':
					style += `
                        border-color: ${theme.colors.tertiary};
                        color: ${theme.colors.tertiary};
                    `;
					break;

				case 'white':
					style += `
                        border-color: ${theme.colors.build.light.primary};
                        color: ${theme.colors.build.light.primary};
                    `;
					break;

				case 'transparent':
					style += `
                        border-color: ${theme.colors.build.dark.primary};
                        font-style: normal;
                    `;
					break;

				default:
					style += `
                        border-color: ${theme.colors.build.dark.primary};
                    `;
					break;
			}
		}

		if (disabled) {
			style += `
                background: ${theme.colors.build.gray.g100};
                color: ${theme.colors.build.gray.g200};
            `;
		}

		if (loaded) {
			style += `
                &:before,
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                }

                &:before {
                    background: ${theme.colors.tertiary};
                    border-radius: ${theme.decoration.border.radius.sm};
                    height: 100%;
                    left: 0;
                    top: 0;
                    width: 100%;
                    z-index: 3;
                }

                &:after {
                    animation: loadAnimation 2s infinite linear;
                    border-radius: ${theme.decoration.border.radius.full};
                    border: 3px solid ${theme.colors.build.dark.primary};
                    border-top-color: ${theme.colors.tertiary};
                    height: 24px;
                    left: 50%;
                    margin: -12px 0 0 -12px;
                    top: 50%;
                    width: 24px;
                    z-index: 5;
                }

                @keyframes loadAnimation {
                    0% {
                        transform:rotate(0deg);
                    }
                    100% {
                        transform:rotate(360deg);
                    }
                }
            `;
		}

		if (size) {
			switch (size) {
				case 'small':
					style += `
                        border-radius: ${theme.decoration.border.radius.sm};
                        font-size: 16px;
                        height: 32px;
                        min-width: 32px;

                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    `;
					break;

				case 'large':
					style += `
                        border-radius: ${theme.decoration.border.radius.md};
                        font-size: 32px;
                        height: 72px;
                        min-width: 72px;

                        @media (max-width: 767px) {
                            font-size: 28px;
                        }
                    `;
					break;

				default:
					style += `
                        border-radius: ${theme.decoration.border.radius.md};
                        font-size: 22px;
                        height: 52px;
                        min-width: 52px;

                        @media (max-width: 767px) {
                            font-size: 18px;
                        }
                    `;
					break;
			}
		}

		return `
            align-items: center;
            border-radius: ${theme.decoration.border.radius.md};
            border: none;
            display: inline-flex;
            font-size: 22px;
            font-style: italic;
            font-weight: 900;
            height: 52px;
            justify-content: center;
            line-height: normal;
            min-width: 52px;
            position: relative;
            text-align: center;
            text-transform: uppercase;

            .icon {
                align-items: center;
                display: inline-flex;
                font-size: 24px;
                height: 32px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }

            ${style}
        `;
	}
);

export default ButtonStyle;
