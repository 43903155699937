import { combineReducers } from 'redux';
import { useSelector } from 'react-redux';

import UserReducer, { IUser } from './UserReducer';

import MiscReducer, { IMisc } from './MiscReducer';

import { ISubscription } from 'src/interfaces/SubscriptionInterface';
import SubscriptionReducer from './SubscriptionReducer';

import { IChallenge } from 'src/interfaces/ChallangeInterface';
import ChallengeReducer from './ChallengeReducer';

export interface IReducer {
	user: IUser;
	misc: IMisc;
	subscription: ISubscription;
	challenge: IChallenge;
}

export default combineReducers({
	user: UserReducer,
	misc: MiscReducer,
	subscription: SubscriptionReducer,
	challenge: ChallengeReducer,
});

export const useRedux = (): IReducer => useSelector((state: IReducer) => state);
