import styled from '@emotion/styled';

import background from 'src/assets/images/background.jpg';

const PrivateLayoutStyle = {
	Wrapper: styled.div`
		background: url(${background}) no-repeat center;
		background-size: cover;
		min-height: inherit;
		position: relative;
		overflow-x: hidden;

		.enterActive,
		.exitActive {
			position: absolute;
			top: 0;
			width: 100%;
		}
	`,

	WrapperAside: styled.aside`
		min-height: inherit;
		padding-bottom: 56px;
		position: relative;
	`,

	Footer: {
		Wrapper: styled.footer(
			({ theme }) => `
            background: ${theme.colors.build.light.primary};
            bottom: 0;
            box-shadow: ${theme.decoration.shadow.default};
            height: 56px;
            left: 50%;
            max-width: 560px;
            position: fixed;
            transform: translateX(-50%);
            width: 100%;
            z-index: 9;            
        `
		),

		Navigation: styled.nav(
			({ theme }) => `
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: calc(100% - 56px);
            a {
                align-items: center;
                background: transparent;
                border: none;
                color: ${theme.colors.build.gray.g500};
                display: flex;
                flex-direction: column;
                font-size: 18px;
                justify-content: center;
                padding: 0 8px;
                height: 56px;
                text-align: center;
                transition: all 0.3s ease-in-out;
                width: 25%;

                span {
                    align-items: center;
                    border-radius: 4px;
                    display: flex;
                    height: 20px;
                    width: 32px;
                    justify-content: center;
                    line-height: 1;
                }

                em {
                    display: block;
                    font-size: 11px;
                    font-style: normal;
                }

                &.active {
                    background: ${theme.colors.gradient.g002};
                    color: ${theme.colors.build.dark.primary};
                    font-size: 18px;
                }
            }
        `
		),

		WhatsApp: styled.a`
			align-items: center;
			bottom: 0;
			background: #25d366;
			color: #fff;
			display: flex;
			font-size: 24px;
			height: 56px;
			justify-content: center;
			position: absolute;
			right: 0;
			width: 56px;
		`,
	},
	WrapperUnsubscribed: styled.div`
		background: url(${background}) no-repeat center;
		background-size: cover;
		min-height: inherit;
		padding: 32px 0;
		width: 100%;

		figure {
			display: block;
			margin: 0 0 40px;
			text-align: center;
			img {
				max-width: 50%;
			}
		}
	`,
};

export default PrivateLayoutStyle;
