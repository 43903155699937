import styled from '@emotion/styled';

import background from 'src/assets/images/background.jpg';
import backgroundClear from 'src/assets/images/background-clear.jpg';

const SignLayoutStyle = {
  Form: {
    Wrapper: styled.div<{ clean?: boolean }>(
      ({ theme, clean }) => `
			align-items: stretch;
            background: url(${
              clean ? backgroundClear : background
            }) no-repeat center top fixed;
            background-size: cover;
			display: flex;
			flex-direction: column;
			min-height: ${theme.decoration.sizes.body};
		`
    ),
    Header: styled.header`
      flex-shrink: 0;
      padding: 48px 0;
      text-align: center;

      img {
        height: auto;
        max-width: 160px;
        width: 100%;
      }
    `,
    Content: styled.div`
      flex: 1 0 auto;
    `,
    Card: styled.div(
      ({ theme }) => `
                background: ${theme.colors.build.light.primary};
                box-shadow: ${theme.decoration.shadow.default};
                border-radius: ${theme.decoration.border.radius.md};
                padding: 32px;

				@media (max-width: 580px) {
					padding: 24px;
				}
            `
    ),
    Footer: styled.footer`
      align-items: center;
      display: flex;
      flex-shrink: 0;
      font-size: 11px;
      font-weight: bold;
      height: 64px;
      justify-content: center;
    `,
  },
  Error: {
    Header: styled.div`
      padding: 24px 16px 32px;
      position: relative;
    `,
    HeaderButton: styled.button`
      background: transparent;
      border: none;
      padding: 0;

      img {
        max-width: 60px;
        width: 100%;
      }
    `,
    Title: styled.h2(
      ({ theme }) => `
				${theme.typography.title.t3}
				margin: 0 0 8px;
				text-transform: uppercase;
			`
    ),
    Logoheader: styled.div`
      height: auto;
      width: 120px;
      img {
        max-width: 100%;
        height: auto;
      }
    `,
    Image: styled.figure`
      display: block;
      margin: 0;
      padding-top: 32px;

      img {
        display: block;
        margin: 0 auto;
        max-height: 250px;
        width: auto;
      }
    `,
  },
  ErrorCode: styled.div(
    ({ theme }) => `
		padding: 0 24px;
		text-align: center;

		figure {
			margin: 0 auto 32px;
			padding: 16px 24px 0;

			img {
				max-width: 360px;
				width: 100%;
			}
		}

		p {
			${theme.typography.body.standard}
			color: ${theme.colors.build.gray.g400};
		}
	`
  ),
};

export default SignLayoutStyle;
