import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { Helpers } from 'src/utils/helpers';

import { useRedux } from 'src/redux';

import ChallengeCardsStyle from './ChallengeCardsStyle';

import useSubscription from 'src/hooks/useSubscription';

interface IItem {
	day: number;
	check: boolean;
	enabled: boolean;
}

const ChallengeCards: React.FC = () => {
	const navigate = useNavigate();

	const { challenge } = useRedux();

	const { dateStart } = useSubscription();

	const [challenges, setChallenges] = useState<Array<IItem>>([]);

	const handleClick = (item: IItem): void => {
		if (item.enabled) {
			navigate(`/app/desafio/${item.day}`);
		}
	};

	const { checkSubscription } = useSubscription();

	useEffect(() => {
		const items: Array<IItem> = [];
		for (let index = 0; index < challenge.days; index++) {
			items[index] = {
				day: index + 1,
				check: checkSubscription(index),
				enabled: index < Helpers.activitieNumber(dateStart),
			};
		}
		setChallenges(items);
	}, [challenge.days, dateStart, checkSubscription]);
	return (
		<ChallengeCardsStyle.Wrapper>
			<aside>
				{challenges.map((item: IItem, index) => (
					<ChallengeCardsStyle.Item
						key={item.day}
						enabled={item.enabled}
						className={item.check ? 'active' : ''}
						onClick={(): void => handleClick(item)}>
						<dl>
							<dt>{moment(dateStart).add(index, 'day').format('ddd')}</dt>
							<dd>{moment(dateStart).add(index, 'day').format('DD')}</dd>
						</dl>
						<i
							className={
								item.check
									? 'ri-checkbox-circle-line'
									: item.enabled
									? 'ri-checkbox-blank-circle-line'
									: 'ri-time-line'
							}
						/>
					</ChallengeCardsStyle.Item>
				))}
			</aside>
		</ChallengeCardsStyle.Wrapper>
	);
};

export default ChallengeCards;
