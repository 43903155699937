import React from 'react';

import styled from '@emotion/styled';

import { WaterMeter, WaterActions } from 'src/components/molecules';

const Wrapper = styled.div(
	({ theme }) => `
  background: ${theme.colors.build.light.primary};
  border-radius: ${theme.decoration.border.radius.md};
  box-shadow: ${theme.decoration.shadow.default};
  margin-bottom: 32px;
  padding: 24px;
  position: relative;
  width: 100%;
`
);

const Actions = styled.div(
	({ theme }) => `
  background: ${theme.colors.build.gray.g100};
  border-radius: ${theme.decoration.border.radius.md};
  padding: 0 16px 16px;
`
);

export const WaterSubscription: React.FC<{ day: number }> = ({ day }) => {
	return (
		<Wrapper>
			<div className='mb-5'>
				<WaterMeter day={day} />
			</div>
			<Actions>
				<div className='row'>
					<div className='col-6'>
						<WaterActions day={day} size={300} />
					</div>
					<div className='col-6'>
						<WaterActions day={day} size={500} />
					</div>
					<div className='col-6'>
						<WaterActions day={day} size={750} />
					</div>
					<div className='col-6'>
						<WaterActions day={day} size={1000} />
					</div>
				</div>
			</Actions>
		</Wrapper>
	);
};
