import React from 'react';

import useSubscription from 'src/hooks/useSubscription';

import moment from 'moment';

import { useRedux } from 'src/redux';

import ChallengeProgressStyle from './ChallengeProgressStyle';

const ChallengeProgress: React.FC = () => {
	const { progressChecked, progressPercent, dateStart } = useSubscription();

	const { challenge } = useRedux();

	return (
		<ChallengeProgressStyle.Wrapper>
			<div className='row align-items-center no-gutters'>
				<div className='col'>
					<ChallengeProgressStyle.Title>Progresso</ChallengeProgressStyle.Title>
					<ChallengeProgressStyle.Label>
						DESAFIO TERMINA{' '}
						<strong>
							{moment(dateStart).add(challenge.days, 'days').format('DD/MM/YY')}
						</strong>
					</ChallengeProgressStyle.Label>
				</div>
				<div className='col-auto'>
					<ChallengeProgressStyle.Status>
						{progressChecked}/{challenge.days}
					</ChallengeProgressStyle.Status>
				</div>
			</div>
			<ChallengeProgressStyle.Progress progress={progressPercent} />
		</ChallengeProgressStyle.Wrapper>
	);
};

export default ChallengeProgress;
