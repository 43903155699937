import styled from '@emotion/styled';

const HeaderBackStyle = {
	Wrapper: styled.div`
		margin-bottom: 24px;
	`,

	ButtonBack: styled.button`
		background: transparent;
		border: none;
		padding: 0;
		position: relative;
		width: 50px;
	`,

	Title: styled.h2(
		({ theme }) => `
            ${theme.typography.title.t3}
            font-weight: 900;
            font-style: italic;
            margin: 0 0 8px;
            text-transform: uppercase;
        `
	),

	Logo: styled.div`
		width: 120px;

		svg {
			width: 100%;
		}
	`,

	Content: styled.div(
		({ theme }) => `
        ${theme.typography.body.standard}
    `
	),
};

export default HeaderBackStyle;
