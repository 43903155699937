import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';

import { Drawer } from 'antd';

import { useRedux } from 'src/redux';

import { AuthService } from 'src/services';
import { Button, LogoBlack } from '../atoms';

import ilistraBoneco from 'src/assets/images/ilustra-boneco.png';
import bg from 'src/assets/images/background-color.jpg';

import { SignupChallengeForm, SignupChallengeConfirm } from '../forms';

const Wrapper = styled.div({
	alignItems: 'center',
	background: `url(${bg}) no-repeat center`,
	backgroundSize: 'cover',
	display: 'flex',
	minHeight: 'calc(var(--vh, 1vh) * 100)',
	padding: '0 40px',
	textAlign: 'center',
});

const Logo = styled.figure({
	marginBottom: 40,

	svg: {
		height: 'auto',
		maxWidth: 120,
	},
});

const Title = styled.h1({
	fontSize: 28,
	fontStyle: 'italic',
	fontWeight: 'bold',
	marginBottom: 32,
	textTransform: 'uppercase',
});

const Description = styled.p({
	fontSize: 16,
	lineHeight: 1.4,
	margin: 0,
	padding: '16px 0 32px',
});

export const Unsubscribed = () => {
	const dispatch = useDispatch();

	const [showSignIn, setShowSignIn] = useState(false);

	const {
		misc: { tokenSignUp },
	} = useRedux();

	const onBack = () => {
		AuthService.logout();
	};

	const resetTokenSignUp = useCallback(() => {
		dispatch({
			type: 'UPDATE_TOKEN_SIGN_UP',
			value: '',
		});
	}, [dispatch]);

	useEffect(() => {
		resetTokenSignUp();
	}, [resetTokenSignUp]);

	return (
		<>
			<Wrapper>
				<div className='container'>
					<Logo>
						<LogoBlack />
					</Logo>

					<Title>Você não está cadastrado em nenhum desafio :(</Title>

					<figure>
						<img src={ilistraBoneco} alt='Nenhum desafio' />
					</figure>

					<Description>
						Clique em <strong>VOLTAR</strong> para sair ou em{' '}
						<strong>CADASTRAR</strong> e inicie um novo desafio!
					</Description>

					<div className='row'>
						<div className='col'>
							<Button
								container
								color='white'
								onClick={() => setShowSignIn(true)}>
								Cadastrar
							</Button>
						</div>
						<div className='col-auto'>
							<Button
								color='white'
								format='ghost'
								onClick={onBack}
								style={{ color: '#000', padding: '0 16px' }}>
								VOLTAR
							</Button>
						</div>
					</div>
				</div>
			</Wrapper>

			<Drawer
				visible={showSignIn}
				placement='bottom'
				onClose={() => setShowSignIn(false)}>
				{tokenSignUp ? <SignupChallengeConfirm /> : <SignupChallengeForm />}
			</Drawer>
		</>
	);
};
