import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ModalVideo from 'react-modal-video';

import { useRedux } from 'src/redux';

import { MainService } from 'src/services/MainServices';

import { IActivities } from 'src/interfaces/ChallangeInterface';

import ActivitiesCardStyle from './ActivitiesCardStyle';

import { ListHeader } from 'src/components/organisms';

interface IActivitiesCard {
	scheduleId: string;
	item: {
		_id: string;
		instant: string;
		activities: IActivities;
	};
}

const ActivitiesCard: React.FC<IActivitiesCard> = ({ item, scheduleId }) => {
	const { subscription, challenge } = useRedux();

	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [openVideo, setOpenVideo] = useState('');

	const initSubscription = () => {
		const challengeIndex = challenge.schedule?.find(
			(i) => i._id === scheduleId
		);
		const goingsOn: any = [];
		challengeIndex?.goingsOn.forEach((i) => {
			if (i.activities && i.activities.tasks) {
				goingsOn.push({
					...i,
					activities: {
						tasks: i.activities.tasks?.map((task) => ({
							...task,
							completed: false,
						})),
					},
				});
			}
		});

		return {
			...challengeIndex,
			goingsOn,
		};
	};

	const sumActivities = (): number => {
		let sum = 0;

		if (subscription.schedule?.length) {
			const day = subscription.schedule?.findIndex((i) => {
				if (i) {
					return i._id === scheduleId;
				}
				return false;
			});

			if (subscription.schedule[day]) {
				const goingsOnIndex = subscription.schedule[day].goingsOn.findIndex(
					(i) => i._id === item._id
				);
				subscription.schedule[day].goingsOn[
					goingsOnIndex
				].activities.tasks.forEach((element) => {
					sum += element.completed ? 1 : 0;
				});
			}
		}

		return sum;
	};

	const toggleOpen = (): void => {
		setOpen(!open);
	};

	const checkItems = (id: string): boolean => {
		if (subscription.schedule?.length) {
			const day = subscription.schedule?.findIndex((i) => {
				if (i) {
					return i._id === scheduleId;
				}

				return false;
			});

			if (subscription.schedule[day]) {
				const goingsOn = subscription.schedule[day].goingsOn.findIndex(
					(i) => i._id === item._id
				);
				const index = subscription.schedule[day].goingsOn[
					goingsOn
				].activities.tasks.findIndex((i) => i._id === id);
				return !!subscription.schedule[day].goingsOn[goingsOn].activities.tasks[
					index
				]?.completed;
			}

			return false;
		} else {
			return false;
		}
	};

	const toggleCheck = (id: string): void => {
		let subscribed;

		if (subscription.schedule?.length) {
			subscribed = subscription;

			const dayIndex = subscribed.schedule?.findIndex((i) => {
				if (i) {
					return i._id === scheduleId;
				}

				return false;
			});

			if (dayIndex < 0) {
				subscribed.schedule.push(initSubscription());
			}
		} else {
			subscribed = {
				...subscription,
				schedule: [initSubscription()],
			};
		}

		if (subscribed.schedule) {
			const day = subscribed.schedule?.findIndex((i) => {
				if (i) {
					return i._id === scheduleId;
				}

				return false;
			});

			const goingsOnIndex = subscribed.schedule[day].goingsOn.findIndex(
				(i) => i._id === item._id
			);

			const index = subscribed.schedule[day].goingsOn[
				goingsOnIndex
			].activities.tasks.findIndex((i) => i._id === id);

			subscribed.schedule[day].goingsOn[goingsOnIndex].activities.tasks[
				index
			].completed =
				!subscribed.schedule[day].goingsOn[goingsOnIndex].activities.tasks[
					index
				].completed;

			dispatch({
				type: 'SUBSCRIPTION_UPDATE',
				value: { ...subscribed },
			});

			MainService.fetch(`subscription/onboarding/${subscribed._id}`, 'patch', {
				...subscribed,
			});
		}
	};

	const translateName = (title: string): string => {
		switch (title) {
			case 'Lanche Manhã':
				return 'Café da Manhã';

			case 'Em jejum':
				return 'Ao Acordar';

			default:
				return title;
		}
	};

	return (
		<ActivitiesCardStyle.Wrapper className={open ? 'active' : ''}>
			<ActivitiesCardStyle.Header onClick={() => toggleOpen()}>
				<ListHeader
					{...{
						icon: item.instant,
						title: translateName(item.instant),
						items:
							item.activities && item.activities.tasks
								? item.activities.tasks.length
								: 0,
						itemLabel: 'Atividades',
						itemsChecked: sumActivities(),
						open: open,
					}}
				/>
			</ActivitiesCardStyle.Header>

			<ActivitiesCardStyle.Content show={open}>
				{item.activities?.midia && item.activities.midia.length ? (
					<ActivitiesCardStyle.CardItem>
						<ul className='media'>
							{item.activities.midia.map((midia, index) => (
								<li key={`midia-${index}-${item.instant}`}>
									<ModalVideo
										url={midia.secure_url}
										channel='custom'
										isOpen={midia.secure_url === openVideo}
										onClose={() => setOpenVideo('')}
										oncontextmenu='return false;'
									/>
									<div className='row align-items-center no-gutters'>
										<div className='col-auto'>
											<div className='action'>
												<button
													onClick={() => setOpenVideo(midia.secure_url)}
													className='buttonPlay'>
													<i className='ri-play-mini-fill'></i>
												</button>
											</div>
										</div>
										<div className='col'>
											<h3>{midia.name}</h3>
										</div>
									</div>
								</li>
							))}
						</ul>
					</ActivitiesCardStyle.CardItem>
				) : (
					<></>
				)}

				{item.activities?.tasks && item.activities.tasks.length ? (
					<ActivitiesCardStyle.CardItem>
						<ul className='tasks'>
							{item.activities.tasks.map((tasks, index) => (
								<li
									key={`tasks-${index}-${item.instant}`}
									className={checkItems(tasks._id) ? 'checked' : ''}>
									<span
										className='radio'
										onClick={() => toggleCheck(tasks._id)}>
										<i
											className={`ri-checkbox${
												checkItems(tasks._id) ? '' : '-blank'
											}-circle-line`}
										/>
									</span>
									<em onClick={() => toggleCheck(tasks._id)}>{tasks?.label}</em>
									{tasks.cookbook && (
										<NavLink to={`/app/receitas/${tasks.cookbook}`}>
											Receita
										</NavLink>
									)}
								</li>
							))}
						</ul>
					</ActivitiesCardStyle.CardItem>
				) : (
					<></>
				)}

				{item.activities?.text ? (
					<ActivitiesCardStyle.CardItem>
						<p>{item.activities.text}</p>
					</ActivitiesCardStyle.CardItem>
				) : (
					<></>
				)}
			</ActivitiesCardStyle.Content>
		</ActivitiesCardStyle.Wrapper>
	);
};

export default ActivitiesCard;
