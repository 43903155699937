import styled from '@emotion/styled';

const ListHeaderStyle = {
	Wrapper: styled.div`
		position: relative;
	`,

	Icon: styled.div<{ checked: boolean }>(
		({ theme, checked }) => `
		margin-right: 16px;
		position: relative;
		width: 56px;

		span {
			align-items: center;
			background: ${theme.colors.action.success};
			border-radius: ${theme.decoration.border.radius.full};
			border: 2px solid ${theme.colors.build.light.primary};
			color: ${theme.colors.build.light.primary};
			display: flex;
			font-size: 16px;
			height: 24px;
			justify-content: center;
			line-height: normal;
			position: absolute;
			right: -8px;
			top: -8px;
			width: 24px;

			i{
				display: block;
			}
		}

		svg {
			width: 100%;

			path,
			circle {
				fill: ${checked ? theme.colors.action.success : theme.colors.build.brand.b003};
			}
		}

		@media (max-width: 330px) {
			margin-right: 16px;
			width: 40px;
		}
	`
	),

	Title: styled.div(
		({ theme }) => `
			${theme.typography.title.t4}
			font-style: italic;
			font-weight: 900;
			text-transform: uppercase;

			@media (max-width: 330px) {
				${theme.typography.title.t6}
			}
		`
	),
	ItemLabel: styled.div(
		({ theme }) => `
		${theme.typography.body.standard}
	`
	),

	Action: styled.div<{ open: boolean }>(
		({ theme, open }) => `
			color: ${theme.colors.build.dark.primary};
			font-size: 28px;
			line-height: normal;
			margin-top: -14px;
			position: absolute;
			right: 0;
			top: 50%;
			transition: all 0.3s ease-in-out;

			i {
				display: block;
			}

			${open ? 'transform: rotate(180deg);' : ''}
	`
	),
};

export default ListHeaderStyle;
