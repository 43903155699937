import React from 'react';
import { useRedux } from 'src/redux';

import ProfileStyle from './ProfileStyle';

import logoDesafio from 'src/assets/images/logo-desafio.png';
import janroseRennovit from 'src/assets/images/janrose-rennovit.png';
import slogan from 'src/assets/images/slogan.png';

const ProfileShare: React.FC = () => {
	const { user } = useRedux();

	return (
		<ProfileStyle.Share.Wrapper>
			<div className='container'>
				<ProfileStyle.Share.Logo>
					<img src={logoDesafio} alt='Desafio Rennovit' />
				</ProfileStyle.Share.Logo>

				<ProfileStyle.Share.Avatar>
					<img
						src={
							user.imageUrl ||
							`https://avatar.oxro.io/avatar.svg?name=${encodeURI(
								user.name || ''
							)}&background=000000&caps=3&bold=true`
						}
						alt={user.name}
					/>
				</ProfileStyle.Share.Avatar>

				<ProfileStyle.Share.Title>
					<span>
						<em>{user.name}</em>
					</span>
				</ProfileStyle.Share.Title>

				<ProfileStyle.Share.Slogan>
					<img src={slogan} alt='Eu vou mudar minha vida' />
				</ProfileStyle.Share.Slogan>

				<ProfileStyle.Share.JanRose>
					<img src={janroseRennovit} alt='Jan Rosê' />
				</ProfileStyle.Share.JanRose>
			</div>
		</ProfileStyle.Share.Wrapper>
	);
};

export default ProfileShare;
