import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Form, Input, Divider, message, Modal } from 'antd';

import { Mixpanel } from 'src/services/Mixpanel';

import { MainService } from 'src/services/MainServices';
import AuthService from 'src/services/AuthService';

import { TFormStatus } from 'src/interfaces/CommonInterfaces';

import SignStyle from './SignStyle';

import SignForm from 'src/layout/SignLayout/SignForm';

import { Button } from 'src/components/atoms';

const SignLogin: React.FC = () => {
	const [form] = Form.useForm();

	const [status, setStatus] = useState<{
		emailLabel: TFormStatus;
		passLabel: TFormStatus;
	}>({
		emailLabel: '',
		passLabel: '',
	});

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const onFinish = async (values: {
		email: string;
		password: string;
	}): Promise<void> => {
		setLoading(true);

		const res = await MainService.fetch('users/login', 'post', {
			email: values.email,
			password: values.password,
		});

		Mixpanel.track('Login', {
			status: res.status,
			email: values.email,
		});

		if (res.status === 'success') {
			AuthService.registerToken(res.token, res.data.user);
			navigate('/app/dashboard');
		} else if (res.status === 'error') {
			if (res.data.field) {
				if (res.data.field === 'password') {
					setStatus({ emailLabel: 'success', passLabel: 'error' });
				} else {
					setStatus({ ...status, emailLabel: 'error' });
				}
			}
			Modal.warning({
				title: 'ACESSO',
				content: res.data.message,
			});
		} else {
			message.error(
				'Estamos com problema em nossos servidores, tente novamente mais tarde'
			);
		}

		setLoading(false);
	};

	useEffect(() => {
		if (AuthService.isAuthenticated()) {
			navigate('/app/dashboard');
		}
	});

	return (
		<SignForm>
			<Form
				layout='vertical'
				form={form}
				onFinish={onFinish}
				data-testid='login-form'>
				<Form.Item
					label='Email'
					name='email'
					hasFeedback
					validateStatus={status.emailLabel}
					rules={[
						{ required: true, message: 'Digite seu email' },
						{
							required: true,
							type: 'email',
							message: 'Insira um email valido',
						},
					]}>
					<Input
						placeholder='Digite seu Email'
						disabled={loading}
						data-testid='input-email'
						type='email'
					/>
				</Form.Item>

				<Form.Item
					label='Senha'
					name='password'
					hasFeedback
					validateStatus={status.passLabel}>
					<Input.Password
						placeholder='Digite sua senha'
						disabled={loading}
						data-testid='input-pass'
					/>
				</Form.Item>

				<div className='row align-items-center'>
					<div className='col'>
						<Button
							container
							disabled={loading}
							loaded={loading}
							data-testid='continuar-button'>
							CONTINUAR
						</Button>
					</div>
					<div className='col-auto'>
						<Link to='/esqueceu-senha'>
							<SignStyle.LinkSmall>Esqueci minha senha</SignStyle.LinkSmall>
						</Link>
					</div>
				</div>
			</Form>

			<Divider className='mt-4 mb-2'>OU</Divider>

			<Button
				container
				color='transparent'
				onClick={() => navigate('/cadastro')}
				data-testid='sign-button'>
				FAÇA SEU CADASTRO
			</Button>
		</SignForm>
	);
};

export default SignLogin;
