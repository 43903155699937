import React from 'react';

import HighlightHeaderStyle from './HighlightHeaderStyle';

interface IHighlightHeader {
	title: string;
}

const HighlightHeader: React.FC<IHighlightHeader> = ({ title, children }) => {
	return (
		<HighlightHeaderStyle.Wrapper>
			<h2>{title}</h2>
			<p>{children}</p>
		</HighlightHeaderStyle.Wrapper>
	);
};

export default HighlightHeader;
