import React, { useState } from 'react';
import Countdown from 'react-countdown';
import moment from 'moment';
import { Player } from 'video-react';

import { Drawer } from 'antd';

import { Mixpanel, AuthService } from 'src/services';

import { Helpers } from 'src/utils/helpers';

import useSubscription from 'src/hooks/useSubscription';

import CountDownStyle from './CountDownStyle';

import title from 'src/assets/images/falta-pouco.png';

import { HeaderBack, Button } from 'src/components/atoms';

import ShoppingListCards from 'src/components/common/ShoppingListCards';
import HighlightHeader from 'src/components/common/HighlightHeader';
import { useRedux } from 'src/redux';

interface ICountDown {
	isDashboard?: boolean;
}

export const CountDown: React.FC<ICountDown> = ({ isDashboard }) => {
	const [showList, setShowList] = useState(false);

	const { dateStart } = useSubscription();

	const { challenge } = useRedux();

	const renderCountDown = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <></>;
		} else {
			return (
				<>
					<CountDownStyle.CountDownItem>
						<strong>{days}</strong>
						dias
					</CountDownStyle.CountDownItem>
					<CountDownStyle.CountDownItem>
						<strong>{hours}</strong>
						horas
					</CountDownStyle.CountDownItem>
					<CountDownStyle.CountDownItem>
						<strong>{minutes}</strong>
						minutos
					</CountDownStyle.CountDownItem>
					<CountDownStyle.CountDownItem>
						<strong>{seconds}</strong>
						segundos
					</CountDownStyle.CountDownItem>
				</>
			);
		}
	};

	const toggleList = (): void => {
		Mixpanel.track('Lista de Compras', {
			toggleList: 'CountDown',
		});

		setShowList(!showList);
	};

	const formatDateChallengeStart = () => {
		const startSubscription = moment(dateStart?.toString().substr(0, 10));
		const startEvent = moment(Helpers.startEvent);

		return startEvent.isAfter(startSubscription)
			? startEvent.format()
			: startSubscription.format();
	};

	return (
		<>
			<CountDownStyle.Wrapper isDashboard={isDashboard}>
				<div className='container'>
					<HeaderBack logo back={() => AuthService.logout()} />

					<CountDownStyle.Title>
						<img src={title} alt='Falta pouco' />
					</CountDownStyle.Title>

					<CountDownStyle.Content>
						UM NOVO DESAFIO COMEÇA EM:
					</CountDownStyle.Content>

					<CountDownStyle.CountDown>
						<Countdown
							date={formatDateChallengeStart()}
							renderer={renderCountDown}
						/>
					</CountDownStyle.CountDown>

					<div
						className='mb-4'
						style={{ borderRadius: '8px', overflow: 'hidden' }}>
						<Player playsInline src={challenge.videoCover} />
					</div>

					<CountDownStyle.Footer>
						<p>Enquanto isso organize sua lista de compras para este desafio</p>

						<Button container onClick={toggleList}>
							Ir para a lista de compras
						</Button>
					</CountDownStyle.Footer>
				</div>
			</CountDownStyle.Wrapper>

			<Drawer
				visible={showList}
				height='100%'
				placement='bottom'
				title='Lista de Compras'
				onClose={toggleList}>
				<CountDownStyle.ShoppingList>
					<article className='mb-3'>
						<HighlightHeader title='IMPORTANTE:'>
							Antes de ir as compras, verifique os itens que você já tem na sua
							dispensa ou geladeira, reaproveite alimentos, os itens permitidos
							e utlizados serão os que estão neste lista.{' '}
							<strong>Prepare-se!</strong>
						</HighlightHeader>
					</article>
					<ShoppingListCards />
				</CountDownStyle.ShoppingList>
			</Drawer>
		</>
	);
};
