import styled from '@emotion/styled';

import bg1 from './highlight-1.jpg';
import bg2 from './highlight-2.jpg';
import bg3 from './highlight-3.jpg';
import bg4 from './highlight-4.jpg';
import bg5 from './highlight-5.jpg';
import bg6 from './highlight-6.jpg';
import bg7 from './highlight-7.jpg';

const ActiveHighlightStyle = {
	Wrapper: styled.div<{ active: number }>(({ theme, active }) => {
		let bg = bg1;
		switch (active) {
			case 2:
				bg = bg2;
				break;

			case 3:
				bg = bg3;
				break;

			case 4:
				bg = bg4;
				break;

			case 5:
				bg = bg5;
				break;

			case 6:
				bg = bg6;
				break;

			case 7:
				bg = bg7;
				break;

			default:
				bg = bg1;
				break;
		}
		return `
				background: url(${bg}) no-repeat center;
				background-size: cover;
				border-radius: ${theme.decoration.border.radius.md};
				margin: 0;
				overflow: hidden;
				padding: 56px 32px;
				position: relative;
		
				dl {
					color: ${theme.colors.build.light.primary};
					font-style: italic;
					margin: 0 0 16px;
					dt {
						font-size: 19px;
						font-weight: bold;
						text-transform: uppercase;
					}
		
					dd {
						font-size: 90px;
						font-weight: bold;
						line-height: 0.7;
						margin: 0;
						padding: 0;
						transform: translateX(-9px);
					}
				}
			}
		`;
	}),
};

export default ActiveHighlightStyle;
