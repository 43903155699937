import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { message, Spin } from 'antd';

import { useRedux } from 'src/redux';

import { Mixpanel } from 'src/services/Mixpanel';

import { MainService, AuthService } from 'src/services';

import PrivateLayoutStyle from './PrivateLayoutStyle';

import illustra from 'src/assets/images/illustra-error-code.png';

import { Button, HeaderBack } from 'src/components/atoms';

import { Unsubscribed } from 'src/components/organisms';

import OnBoarding from 'src/pages/onboarding';

const textWhatsapp = encodeURI(
	'Olá estou participando da Detox Week e estou vom uma dúvida pode me ajudar?'
);

const PrivateLayout: React.FC = ({ children }) => {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [isLoading, setLoading] = useState(false);

	const {
		user,
		subscription,
		misc: { statusMobile },
	} = useRedux();

	const actionStatusUpdate = useCallback(
		(status: string) => {
			dispatch({ type: 'UPDATE_MISC_STATUS', value: status });
		},
		[dispatch]
	);

	const checkChallenge = useCallback(() => {
		MainService.fetch('subscription/check').then((res) => {
			setLoading(false);

			if (res.status === 'success') {
				dispatch({ type: 'CHALLENGE_UPDATE', value: res.challenge });
				dispatch({
					type: res.data.challengeStart
						? 'SUBSCRIPTION_NEW'
						: 'SUBSCRIPTION_RESET',
					value: res.data,
				});

				actionStatusUpdate('');
			} else {
				if (res.data === 'Nenhuma inscrição ativa') {
					actionStatusUpdate('unsubscriber');
				}
			}
		});
	}, [dispatch, actionStatusUpdate]);

	const getProfile = useCallback(async () => {
		if (!AuthService.isAuthenticated()) {
			navigate('/login');
		}

		dispatch({ type: 'LOADING_SHOW' });
		await checkChallenge();

		const res = await MainService.fetch('users/profile');

		dispatch({ type: 'LOADING_HIDE' });

		if (res.status === 'success') {
			dispatch({ type: 'USER_UPDATE', value: res.data });
			Mixpanel.identify(res.data._id);
			Mixpanel.people(res.data);
		} else {
			message.error('Erro ao acessar seu perfil, por favor tente mais tarde!');
			AuthService.logout();
		}
	}, [checkChallenge, dispatch, navigate]);

	const isOnboarding =
		!subscription.weightStart ||
		!subscription.abdomenStart ||
		!subscription.hipStart ||
		!subscription.challengeStart;

	useEffect(() => {
		getProfile();
		checkChallenge();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.email, statusMobile]);

	if (isLoading) {
		return <Spin spinning />;
	}

	if (statusMobile === 'expired') {
		return (
			<PrivateLayoutStyle.WrapperUnsubscribed>
				<div className='container'>
					<HeaderBack title='Desafio Encerrado' back={AuthService.logout}>
						Você já completou este desafio ou está inacessível. Clique abaixo
						para sair e faça o seu cadastro para um novo desafio.
					</HeaderBack>

					<figure>
						<img src={illustra} alt='Código inválido' />
					</figure>

					<Button container onClick={() => AuthService.logout()}>
						SAIR
					</Button>
				</div>
			</PrivateLayoutStyle.WrapperUnsubscribed>
		);
	}

	if (statusMobile === 'unsubscriber') {
		return <Unsubscribed />;
	}

	if ((subscription && isOnboarding) || statusMobile === 'onboarding') {
		return <OnBoarding isDashboard />;
	}

	return (
		<PrivateLayoutStyle.Wrapper>
			<PrivateLayoutStyle.WrapperAside>
				{children}
			</PrivateLayoutStyle.WrapperAside>
			<PrivateLayoutStyle.Footer.Wrapper>
				<PrivateLayoutStyle.Footer.Navigation>
					<NavLink to='/app/dashboard'>
						<span>
							<i className='ri-run-fill'></i>
						</span>
						<em>Atividades</em>
					</NavLink>
					<NavLink to='/app/receitas'>
						<span>
							<i className='ri-restaurant-fill'></i>
						</span>
						<em>Receitas</em>
					</NavLink>
					<NavLink to='/app/lista-de-compras'>
						<span>
							<i className='ri-shopping-basket-2-line'></i>
						</span>
						<em>Compras</em>
					</NavLink>
					<NavLink to='/app/profile'>
						<span>
							<i className='ri-user-line'></i>
						</span>
						<em>Perfil</em>
					</NavLink>
				</PrivateLayoutStyle.Footer.Navigation>
				<PrivateLayoutStyle.Footer.WhatsApp
					href={`https://wa.me/554195165619?text=${textWhatsapp}`}
					target='_blank'>
					<i className='ri-whatsapp-line'></i>
				</PrivateLayoutStyle.Footer.WhatsApp>
			</PrivateLayoutStyle.Footer.Wrapper>
		</PrivateLayoutStyle.Wrapper>
	);
};

export default PrivateLayout;
