import React from 'react';

import { useRedux } from 'src/redux';

export const Thumbnail: React.FC = () => {
	const { user } = useRedux();
	return (
		<img
			src={
				user.imageUrl ||
				`https://avatar.oxro.io/avatar.svg?name=${encodeURI(
					user.name || ''
				)}&background=FFFFFF&color=000000&caps=3&bold=true`
			}
			alt={user.name}
		/>
	);
};
