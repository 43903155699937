import React from 'react';

import { Button } from 'src/components/atoms';

import OnBoardingStartStyle from './OnBoardingStartStyle';

import logo from 'src/assets/images/logo-bw.svg';
import highlight from 'src/assets/images/illustra-onboarding-2.png';

const OnBoardingStart: React.FC<{ nextStep: () => void }> = ({ nextStep }) => {
	return (
		<OnBoardingStartStyle.Wrapper>
			<div className='container'>
				<OnBoardingStartStyle.Logo>
					<img src={logo} alt='Desafio Rennovit' />
				</OnBoardingStartStyle.Logo>

				<OnBoardingStartStyle.Highlight>
					<img src={highlight} alt='Iniciar desafio' />
				</OnBoardingStartStyle.Highlight>

				<OnBoardingStartStyle.Button>
					<Button
						color='white'
						container
						data-testid='onboarding-start-button'
						onClick={nextStep}>
						Iniciar o desafio
					</Button>
				</OnBoardingStartStyle.Button>
			</div>
		</OnBoardingStartStyle.Wrapper>
	);
};

export default OnBoardingStart;
