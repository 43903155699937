import React from 'react';
import slugfy from 'slugify';

// Icons
import {
	IconActivities1,
	IconActivities2,
	IconActivities3,
	IconActivities4,
	IconActivities5,
	IconActivities6,
	IconActivities7,
	IconActivities8,
	IconShopping1,
	IconShopping2,
	IconShopping3,
	IconShopping4,
	IconShopping5,
	IconShopping6,
	IconShopping7,
	IconShopping8,
	IconShopping9,
} from 'src/components/atoms';

import ListHeaderStyle from './ListHeaderStyle';

interface IListHeader {
	icon?: string;
	items: number;
	itemsChecked: number;
	itemLabel: string;
	title: string;
	open: boolean;
}

export const ListHeader: React.FC<IListHeader> = ({
	title,
	icon,
	items,
	itemsChecked,
	itemLabel,
	open,
}) => {
	const getIcon = (slug): JSX.Element => {
		switch (slug) {
			case 'em-jejum':
				return <IconActivities1 />;

			case 'lanche-manha':
				return <IconActivities2 />;

			case 'almoco':
				return <IconActivities3 />;

			case 'lanche':
				return <IconActivities4 />;

			case 'jantar':
				return <IconActivities5 />;

			case 'hiitflexibilidade':
				return <IconActivities6 />;

			case 'fortalecimento':
				return <IconActivities7 />;

			case 'rituais-noite':
				return <IconActivities8 />;

			case 'verduras':
			case 'verduraslegumes':
			case 'verduras-e-legumes':
			case 'legumes':
				return <IconShopping1 />;

			case 'frutas':
				return <IconShopping2 />;

			case 'carnes':
				return <IconShopping3 />;

			case 'outras':
			case 'outros':
				return <IconShopping4 />;

			case 'gorduras':
			case 'gorduras-boas':
				return <IconShopping5 />;

			case 'proibidos':
				return <IconShopping6 />;

			case 'carboidratos':
				return <IconShopping7 />;

			case 'oleaginosas':
				return <IconShopping8 />;

			case 'temperos':
				return <IconShopping9 />;

			default:
				return <IconShopping6 />;
		}
	};
	return (
		<ListHeaderStyle.Wrapper>
			<div className='row no-gutters'>
				{icon && (
					<div className='col-auto'>
						<ListHeaderStyle.Icon checked={itemsChecked === items}>
							{itemsChecked === items && (
								<span>
									<i className='ri-check-line'></i>
								</span>
							)}
							{getIcon(slugfy(icon, { lower: true }))}
						</ListHeaderStyle.Icon>
					</div>
				)}
				<div className='col'>
					<ListHeaderStyle.Title>{title}</ListHeaderStyle.Title>
					<ListHeaderStyle.ItemLabel>
						{itemLabel}:{' '}
						<strong>
							{itemsChecked}/{items}
						</strong>
					</ListHeaderStyle.ItemLabel>
				</div>
			</div>
			<ListHeaderStyle.Action open={open}>
				<i className='ri-arrow-down-s-line'></i>
			</ListHeaderStyle.Action>
		</ListHeaderStyle.Wrapper>
	);
};
