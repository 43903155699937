import styled from '@emotion/styled';

const OnBoardingWeightStyle = {
	Wrapper: styled.div`
		padding: 40px 0 80px;
	`,
	Content: styled.div`
		width: 100%;
	`,
};

export default OnBoardingWeightStyle;
