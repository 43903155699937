import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import slugfy from 'slugify';

import moment from 'moment';

import { Helpers } from 'src/utils/helpers';

import { ISchedule } from 'src/interfaces/ChallangeInterface';

import { useRedux } from 'src/redux';

import ActivitiesStyle from './ActivitiesStyle';

import { CountDown } from 'src/components/molecules';

import { WaterSubscription } from 'src/components/organisms';

import HeaderPages from 'src/components/common/HeaderPages';
import ActivitiesCard from './ActivitiesCard';

const orderList = [
	'Em jejum',
	'Lanche Manhã',
	'Almoço',
	'Lanche',
	'Jantar',
	'Hiit/Flexibilidade',
	'Fortalecimento',
	'Rituais Noite',
];

const Activities: React.FC = () => {
	const params = useParams();

	const navigate = useNavigate();

	const [schedule, setSchedule] = useState<ISchedule>();
	const [days, setDays] = useState<Array<number>>([]);

	const { challenge } = useRedux();

	const handleBack = (): void => {
		window.history.back();
	};

	const id = parseInt(params.id as string) - 1;

	const handleClick = (index: number): void => {
		if (index <= Helpers.activitieNumber(challenge.dateStart)) {
			navigate(`/app/desafio/${index}`);
		}
	};

	useEffect(() => {
		if (challenge.schedule && challenge.schedule[id]) {
			setSchedule(challenge.schedule[id]);
			const allDays: Array<number> = [];

			for (let index = 0; index < challenge.days; index++) {
				allDays.push(index + 1);
			}

			setDays(allDays);
		}
	}, [challenge.days, challenge.schedule, id]);

	if (
		challenge.dateStart &&
		moment(challenge.dateStart?.toString().substr(0, 10)).isAfter(
			moment(),
			'day'
		)
	) {
		return <CountDown isDashboard />;
	}

	return (
		<ActivitiesStyle.Wrapper>
			<ActivitiesStyle.Header>
				<HeaderPages title='Lista de Atividades' back={handleBack}>
					<ActivitiesStyle.Info>
						<div className='container'>
							<ul>
								{days.map((item) => (
									<li
										className={item - 1 === id ? 'active' : ''}
										onClick={() => handleClick(item)}
										key={`activities-day-${item}`}>
										Dia
										<strong>{item < 10 ? `0${item}` : item}</strong>
									</li>
								))}
							</ul>
						</div>
					</ActivitiesStyle.Info>
				</HeaderPages>
			</ActivitiesStyle.Header>

			<div className='container'>
				<WaterSubscription day={id} />

				{orderList.map((activitie) => {
					const item = schedule?.goingsOn.find((i) => i.instant === activitie);
					if (
						schedule &&
						item &&
						item.activities &&
						item.activities.tasks?.length
					) {
						return (
							<ActivitiesCard
								item={item}
								key={`activities-card-${item._id}-${slugfy(activitie)}`}
								scheduleId={schedule._id}
							/>
						);
					}

					return <div key={`activities-card-${slugfy(activitie)}`} />;
				})}
			</div>
		</ActivitiesStyle.Wrapper>
	);
};

export default Activities;
