const receitasUrl = 'https://webapp259087.ip-72-14-191-150.cloudezapp.io';

const dev = {
	apiUrl: 'https://desafio-rennovit.rj.r.appspot.com/api',
	receitasUrl: receitasUrl + '/wp-admin/admin-ajax.php',
};

const prod = {
	apiUrl:
		process.env.REACT_APP_API_URL ||
		'https://desafio-rennovit.rj.r.appspot.com/api',
	receitasUrl: receitasUrl + '/wp-admin/admin-ajax.php',
};

const props = process.env.NODE_ENV === 'development' ? dev : prod;

const config = {
	...props,
	getFormData: (data: any) => {
		const formData = new FormData();
		Object.keys(data).forEach((key) => formData.append(key, data[key]));
		return formData;
	},
};

export default config;
