import React from 'react';
import { useNavigate } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IReceitasList } from 'src/interfaces/ReceitasInterface';

import ReceitasCardStyle from './ReceitasCardStyle';

interface IReceitasCard {
	item: IReceitasList;
}

const ReceitasCard: React.FC<IReceitasCard> = ({ item }) => {
	const navigate = useNavigate();

	const handleClick = (): void => {
		navigate(`/app/receitas/${item.id}`);
	};

	return (
		<ReceitasCardStyle.Wrapper onClick={handleClick}>
			<aside>
				<div className='row align-items-center'>
					<div className='col'>
						<h2>
							{item.title}
							{item.tags && (
								<div className='tags'>
									{item.tags.map((tag) => (
										<em>{tag.name}</em>
									))}
								</div>
							)}
						</h2>
					</div>
					{item.timer && (
						<div className='col-auto'>
							<span>
								<i className='ri-timer-line'></i>
								{item.timer}
							</span>
						</div>
					)}
				</div>
			</aside>

			<figure>
				<LazyLoadImage
					alt={item.title}
					effect='blur'
					src={item.images}
					visibleByDefault
				/>
			</figure>
		</ReceitasCardStyle.Wrapper>
	);
};

export default ReceitasCard;
