import styled from '@emotion/styled';

const SignStyle = {
	HeroText: styled.dl(
		({ theme }) => `
		margin: 0 0 40px;
		dt {
			${theme.typography.title.t4}
			color: ${theme.colors.build.dark.primary};
			margin-bottom: 0;
			text-transform: uppercase;
		}
		dd {
			${theme.typography.body.standard}
			color: ${theme.colors.build.gray.g400};
		}
	`
	),
	LinkSmall: styled.div(
		({ theme }) => `
		color: ${theme.colors.build.gray.g400};
		font-size: 10px;
		text-transform: uppercase;
		text-decoration: underline;
	`
	),
	ConfirmPass: styled.dl(
		({ theme }) => `
		margin: 0 0 40px;
		text-align: center;
		dt {
			${theme.typography.title.t2}
			color: ${theme.colors.build.dark.primary};
			font-style: italic;
			font-weight: 900;
			text-transform: uppercase;

			figure {
				margin: 0 auto 16px;
				width: 140px;
			}
		}

		dd {
			${theme.typography.body.standard}
		}
	`
	),
};

export default SignStyle;
