import { useState } from 'react';

import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';

import { Upload, Spin, message, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadProps } from 'antd/lib/upload/interface';

import ImgCrop from 'antd-img-crop';

import config from 'src/utils/config';

import { useRedux } from 'src/redux';

import { Style as StyleOnboarding } from 'src/pages/onboarding/OnBoardingPhoto';
import { MainService } from 'src/services/MainServices';
import { Button } from '../atoms';

const Style = {
	...StyleOnboarding,
	Wrapper: styled.div({
		paddingTop: 20,
		paddingBottom: 40,
	}),

	Header: styled.div({
		marginBottom: 16,
		h1: {
			fontSize: 24,
			fontStyle: 'italic',
			fontWeight: 'bold',
			marginBottom: 4,
			textTransform: 'uppercase',
		},

		p: {
			fontSize: 16,
			lineHeight: 1.6,
			margin: 0,
		},
	}),
};

export const PhotoFinished = () => {
	const dispatch = useDispatch();

	const { user, subscription } = useRedux();

	const [isLoading, setLoading] = useState(false);

	const [photo, setPhoto] = useState('');

	const onUploadImage = (info: UploadChangeParam) => {
		const { status } = info.file;

		setLoading(true);

		if (status !== 'uploading') {
			setLoading(true);
		}

		if (status === 'done') {
			if (info.file.response.status === 'success') {
				setPhoto(info.file.response.result.secure_url);

				// const dataImage = {
				// 	photoEnd: info.file.response.result.secure_url,
				// };

				// dispatch({
				// 	type: 'SUBSCRIPTION_UPDATE',
				// 	value: dataImage,
				// });

				message.success(`Upload de ${info.file.name} feito com sucesso.`);
			} else {
				message.error(`Erro ao fazer o upload de ${info.file.name}.`);
			}
			setLoading(false);
		} else if (status === 'error') {
			setLoading(false);
			message.error(`Erro ao fazer o upload de ${info.file.name}.`);
		}
	};

	const settingsUpload: UploadProps = {
		name: 'file',
		action: `${config.apiUrl}/cloudnary`,
		showUploadList: false,
		onChange: onUploadImage,
	};

	const removePhoto = () => {
		setPhoto('');
	};

	const onFinished = async () => {
		try {
			const res = await MainService.fetch(
				`subscription/onboarding/${subscription._id}`,
				'patch',
				{
					photoEnd: photo,
				}
			);

			if (res.status === 'success') {
				dispatch({
					type: 'SUBSCRIPTION_UPDATE',
					value: {
						photoEnd: photo,
					},
				});
			} else {
				Modal.error({
					title: 'Iniciar desafio',
					content:
						'Não foi possível iniciar o desafio, tente novamente mais tarde',
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Spin spinning={isLoading}>
			<Style.Wrapper>
				<div className='container'>
					<Style.Header>
						<h1>Foto Final</h1>
						<p>
							Insira uma foto atual e faça um comparativo de quando iniciou o
							desafio.
						</p>
					</Style.Header>

					<Style.Photo>
						<ImgCrop aspect={4 / 6}>
							<Upload {...settingsUpload}>
								{photo ? (
									<Style.Preview>
										<span className='ri-image-edit-line' />

										<img src={photo} alt={user.name} />
									</Style.Preview>
								) : (
									<button>
										<span className='ri-image-edit-line' /> Adicionar nova foto
									</button>
								)}
							</Upload>
						</ImgCrop>

						{photo && (
							<Style.RemoveImage>
								<button onClick={() => removePhoto()}>Remover Imagem</button>
							</Style.RemoveImage>
						)}
					</Style.Photo>

					<Button container onClick={onFinished} disabled={!photo}>
						Continuar
					</Button>
				</div>
			</Style.Wrapper>
		</Spin>
	);
};
