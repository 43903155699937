import styled from '@emotion/styled';

import bg from 'src/assets/images/background-white.jpg';

const CompleteTasksStyle = {
	Wrapper: styled.div`
		background: url(${bg}) no-repeat center top;
		background-size: 100% auto;
		margin: -56px 0;
		min-height: inherit;
		padding: 80px 0;
		position: relative;
	`,

	Article: styled.article(
		({ theme }) => `
        margin-bottom: 40px;
        h1 {
            ${theme.typography.title.t3}
            font-style: italic;
            font-weight: 900;
            margin: 0;
        }

        p{
            ${theme.typography.body.short}
            margin: 0;
        }
    `
	),
};

export default CompleteTasksStyle;
