import styled from '@emotion/styled';

import background from 'src/assets/images/background-color.jpg';

const OnBoardingStartStyle = {
	Wrapper: styled.div`
		align-items: center;
		background: url(${background}) no-repeat center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: inherit;
		padding: 32px 0 56px;
		width: 100%;
	`,

	Logo: styled.div`
		margin: 0 auto 32px;
		width: 120px;
	`,

	Highlight: styled.div`
		margin: 0 56px 40px;
	`,

	Button: styled.div`
		width: 100%;
	`,
};

export default OnBoardingStartStyle;
