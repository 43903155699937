import styled from '@emotion/styled';

const ReceitasStyle = {
	Header: styled.div`
		margin-bottom: 80px;
	`,

	Info: styled.div`
		margin: 24px 0 0;

		article {
			margin-bottom: -80px;
		}
	`,

	Category: styled.div(
		({ theme }) => `
        display: flex;
        justify-content: space-between;
        margin: 0 0 32px;
        
        button {
            ${theme.typography.body.short}
            background: ${theme.colors.build.brand.b004};
            border-radius: ${theme.decoration.border.radius.sm};
            border: none;
            color: ${theme.colors.build.dark.primary};
            font-weight: 900;
            height: 40px;
            line-height: normal;
            padding: 0 16px;
            text-transform: uppercase;
            width: 100%;

            &.active {
                background: ${theme.colors.gradient.g002};
            }

            @media (max-width: 328px) {
                padding: 0 12px;
            }
        }
    `
	),
};

export default ReceitasStyle;
