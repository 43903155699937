import React, { useState } from 'react';

import * as htmlToImage from 'html-to-image';

import { message, Spin } from 'antd';

import iconTasks from 'src/assets/icons/result-tasks.png';
import iconWeight from 'src/assets/icons/result-weight.png';
import iconAbdomen from 'src/assets/icons/result-abdomen.png';
import iconHip from 'src/assets/icons/result-hip.png';

import useSubscription from 'src/hooks/useSubscription';

import { Mixpanel } from 'src/services/Mixpanel';

import { useRedux } from 'src/redux';

import ResultChallengeStyle from './ResultChallengeStyle';

import { LogoBlack, Button } from 'src/components/atoms';

import { UserImageName } from '../UserImageName';
import slugify from 'slugify';

export const ResultChallenge: React.FC = () => {
	const { progressPercent } = useSubscription();

	const { subscription, challenge, user } = useRedux();

	const [isDownload, setIsDownload] = useState(false);

	const saveImage = (): void => {
		const wrapper = document.getElementById('result-challenge');

		if (wrapper) {
			Mixpanel.track('Compartilhar Resultado', {
				completed: true,
				name: user.name,
				email: user.email,
				id: user._id,
			});

			setIsDownload(true);

			setTimeout(() => {
				htmlToImage.toJpeg(wrapper).then((res) => {
					if (res) {
						var link = document.createElement('a');
						link.download = `${slugify(user.name as string)}-resultado.jpg`;
						link.href = res;
						link.click();
					} else {
						message.error('Erro ao processar sua imagem');
					}
					setIsDownload(false);
				});
			}, 200);
		}
	};

	return (
		<Spin spinning={isDownload}>
			<ResultChallengeStyle.Wrapper
				id='result-challenge'
				isDownload={isDownload}>
				<div className='container'>
					{isDownload && (
						<ResultChallengeStyle.Logo>
							<LogoBlack />
						</ResultChallengeStyle.Logo>
					)}

					{!isDownload && <UserImageName />}

					<ResultChallengeStyle.Preview>
						<dl>
							<dt>Antes</dt>
							<dd>
								<figure>
									<img src={subscription.photoStart} alt='Antes' />
								</figure>
							</dd>
						</dl>
						<dl>
							<dt>Depois</dt>
							<dd>
								<figure>
									<img src={subscription.photoEnd} alt='Depois' />
								</figure>
							</dd>
						</dl>
					</ResultChallengeStyle.Preview>

					<ResultChallengeStyle.Title>
						<span>Meus Resultados em {challenge.days} dias</span>
					</ResultChallengeStyle.Title>

					<ResultChallengeStyle.Results>
						<dl>
							<dt>
								<img src={iconTasks} alt='tarefas' />
								tarefas realizadas
							</dt>
							<dd>{progressPercent.toFixed(0)}%</dd>
						</dl>

						<dl>
							<dt>
								<img src={iconWeight} alt='peso' />
								peso
							</dt>
							<dd>{subscription.weightEnd - subscription.weightStart}kg</dd>
						</dl>

						<dl>
							<dt>
								<img src={iconAbdomen} alt='cintura' />
								cintura
							</dt>
							<dd>{subscription.abdomenEnd - subscription.abdomenStart}cm</dd>
						</dl>

						<dl>
							<dt>
								<img src={iconHip} alt='quadril' />
								quadril
							</dt>
							<dd>{subscription.hipEnd - subscription.hipStart}cm</dd>
						</dl>
					</ResultChallengeStyle.Results>

					<ResultChallengeStyle.Hashtag>
						#euparticipei
					</ResultChallengeStyle.Hashtag>

					{!isDownload && (
						<div className='mt-5'>
							<Button container onClick={saveImage} color='white'>
								Compartilhar
							</Button>
						</div>
					)}
				</div>
			</ResultChallengeStyle.Wrapper>
		</Spin>
	);
};
