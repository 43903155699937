import mixpanel, { Dict } from 'mixpanel-browser';
import { Helpers } from 'src/utils/helpers';

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

export const Mixpanel = {
	identify: (id: string) => {
		if (Helpers.checkEnvDeploy()) mixpanel.identify(id);
	},
	alias: (id: string) => {
		if (Helpers.checkEnvDeploy()) mixpanel.alias(id);
	},
	track: (name: string, props: Dict) => {
		if (Helpers.checkEnvDeploy()) mixpanel.track(name, props);
	},
	people: (props: object) => {
		if (Helpers.checkEnvDeploy()) mixpanel.people.set(props);
	},
};
