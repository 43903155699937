import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { message } from 'antd';

import { IReceitas } from 'src/interfaces/ReceitasInterface';

import ReceitasSingleStyle from './ReceitasSingleStyle';

import { MainService } from 'src/services/MainServices';

import { HeaderPrivate } from 'src/components/organisms';

const ReceitasSingle: React.FC = () => {
	const params = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [data, setData] = useState<IReceitas>();

	const getReceita = useCallback(async (): Promise<void> => {
		dispatch({ type: 'LOADING_SHOW' });

		const res = await MainService.receitas({
			action: 'receitas_single',
			id: params.id,
		});

		if (res.ID) {
			setData(res);
		} else {
			message.error(
				'Erro ao carregar esta receita, tente novamente mais tarde'
			);
			navigate('/app/receitas');
		}

		dispatch({ type: 'LOADING_HIDE' });
	}, [params.id, dispatch, navigate]);

	const htmlEncode = (content: string): string => {
		return content.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
	};

	useEffect(() => {
		getReceita();
	}, [getReceita]);

	if (!data) return null;

	return (
		<ReceitasSingleStyle.Wrapper>
			<HeaderPrivate back />

			<ReceitasSingleStyle.ImageHighlight>
				<ReceitasSingleStyle.Title>
					{data.post_title}
					{data.tags && (
						<div className='tags'>
							{data.tags.map((tag) => (
								<em>{tag.name}</em>
							))}
						</div>
					)}
				</ReceitasSingleStyle.Title>
				<img src={data.feature_image} alt={data.post_title} />
			</ReceitasSingleStyle.ImageHighlight>

			<div className='container'>
				{data.ingredientes.length && (
					<>
						<ReceitasSingleStyle.SubTitle>
							<span>Ingredientes</span>
						</ReceitasSingleStyle.SubTitle>
						<ReceitasSingleStyle.Ingredientes>
							{data.ingredientes.map((i, index) => (
								<li key={`ingrediente-${index}`}>{i.ingrediente}</li>
							))}
						</ReceitasSingleStyle.Ingredientes>
					</>
				)}

				<ReceitasSingleStyle.SubTitle>
					<span>Como preparar</span>
				</ReceitasSingleStyle.SubTitle>
				<ReceitasSingleStyle.Content
					dangerouslySetInnerHTML={{ __html: htmlEncode(data.post_content) }}
				/>
			</div>
		</ReceitasSingleStyle.Wrapper>
	);
};

export default ReceitasSingle;
