import styled from '@emotion/styled';

const ReceitasSingleStyle = {
	Wrapper: styled.div`
		padding-bottom: 56px;
		position: relative;
	`,

	ImageHighlight: styled.div`
		margin-bottom: 32px;
		position: relative;

		img {
			position: relative;
		}

		&:before {
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.1),
				rgba(0, 0, 0, 0.7)
			);
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 1;
		}
	`,

	Title: styled.h1(
		({ theme }) => `
        ${theme.typography.title.t2}
        bottom: 0;
        color: ${theme.colors.build.light.primary};
        left: 0;
        line-height: normal;
        margin: 0;
        padding: 0 24px 32px;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
        z-index: 3;

        .tags {
            align-items: center;
            bottom: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 4px;
            position: absolute;
            width: 100%;

            em {
                ${theme.typography.body.short}
                background: ${theme.colors.build.brand.b003};
                border-bottom-left-radius: ${theme.decoration.border.radius.sm};
                border-top-right-radius: ${theme.decoration.border.radius.sm};
                color: ${theme.colors.build.dark.primary};
                display: inline-block;
                font-style: normal;
                font-weight: bold;
                line-height: normal;
                margin: 0 16px 4px 0;
                padding: 4px 8px;
            }
        }
    `
	),

	SubTitle: styled.h2(
		({ theme }) => `
        ${theme.typography.title.t4}
        color: ${theme.colors.build.dark.primary};
        line-height: normal;
        margin: 0 0 8px;
        position: relative;
        text-transform: uppercase;

        span {
            display: inline-block;
            background: #eeeeee;
            padding-right: 8px;
            position: relative;
            z-index: 2;
        }

        &:before {
            background: ${theme.colors.gradient.g003};
            content: ' ';
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            z-index: 1;
        }
    `
	),

	Ingredientes: styled.ul(
		({ theme }) => `
        margin: 0 0 40px 32px;
        padding: 0;

        li {
            ${theme.typography.body.short}
            padding: 4px 0;
        }
    `
	),

	Content: styled.article(
		({ theme }) => `
        p {
            ${theme.typography.body.short}
        }
    `
	),
};

export default ReceitasSingleStyle;
