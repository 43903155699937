import React from 'react';

import SlideRule from 'react-slide-rule';
import SizesChallengeStyle from './SizesChallengeStyle';

interface ISizesChallenge {
	weight: number;
	abdomen: number;
	hip: number;
	onChange: (size: number, format: string) => void;
}

const SizesChallenge: React.FC<ISizesChallenge> = ({
	onChange,
	weight,
	abdomen,
	hip,
}) => {
	const settings = {
		step: 0.5,
		numberStyle: {
			color: '#b2b2b2',
			family: 'Poppins',
			size: '12px',
			top: 40,
		},
		markStyle: {
			color: '#000',
			width: 0,
			top: 6,
			height: 24,
		},
		smallerMarkStyle: {
			color: '#b2b2b2',
			width: 0,
			height: 18,
			top: 12,
		},
		cursor: <SizesChallengeStyle.Cursor />,
	};

	return (
		<SizesChallengeStyle.Wrapper>
			<SizesChallengeStyle.WrapperSizes>
				<SizesChallengeStyle.Title>
					Informe seu peso atual
				</SizesChallengeStyle.Title>
				<SizesChallengeStyle.Edit>
					<SizesChallengeStyle.Value>
						{weight}
						<sup>kg</sup>
					</SizesChallengeStyle.Value>
					<SizesChallengeStyle.Slider>
						<SlideRule
							{...settings}
							onChange={(e) => onChange(e, 'weight')}
							value={weight}
						/>
					</SizesChallengeStyle.Slider>
				</SizesChallengeStyle.Edit>
			</SizesChallengeStyle.WrapperSizes>

			<SizesChallengeStyle.WrapperSizes>
				<SizesChallengeStyle.Title>
					Informe sua medida do abdomen
				</SizesChallengeStyle.Title>
				<SizesChallengeStyle.Edit>
					<SizesChallengeStyle.Value>
						{abdomen}
						<sup>cm</sup>
					</SizesChallengeStyle.Value>
					<SizesChallengeStyle.Slider>
						<SlideRule
							{...settings}
							onChange={(e) => onChange(e, 'abdomen')}
							value={abdomen}
						/>
					</SizesChallengeStyle.Slider>
				</SizesChallengeStyle.Edit>
			</SizesChallengeStyle.WrapperSizes>

			<SizesChallengeStyle.WrapperSizes>
				<SizesChallengeStyle.Title>
					Informe sua medida do quadril:
				</SizesChallengeStyle.Title>
				<SizesChallengeStyle.Edit>
					<SizesChallengeStyle.Value>
						{hip}
						<sup>cm</sup>
					</SizesChallengeStyle.Value>
					<SizesChallengeStyle.Slider>
						<SlideRule
							{...settings}
							onChange={(e) => onChange(e, 'hip')}
							value={hip}
						/>
					</SizesChallengeStyle.Slider>
				</SizesChallengeStyle.Edit>
			</SizesChallengeStyle.WrapperSizes>
		</SizesChallengeStyle.Wrapper>
	);
};

export default SizesChallenge;
