import React from 'react';

import SignError from 'src/layout/SignLayout/SignError';

import imageError from 'src/assets/images/persona-run.svg';

const SignErrorChallenge: React.FC = () => {
	return (
		<SignError title='Calma, seu desafio começa em breve' image={imageError}>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo, velit
				vel curabitur neque, quam.
			</p>
		</SignError>
	);
};

export default SignErrorChallenge;
