import styled from '@emotion/styled';

interface IStyle {
	transparent?: boolean;
}

const HeaderPrivateStyle = {
	Wrapper: styled.header<IStyle>(
		({ theme, transparent }) => `
        align-items: center;
        background: ${
					transparent ? 'transparent' : theme.colors.build.light.primary
				};
        box-shadow: ${theme.decoration.shadow};
        display: flex;
        flex-wrap: wrap;
        height: 56px;
        justify-content: space-between;
        padding: 0 16px;
        position: relative;
        width: 100%;
        z-index: 3;
    `
	),
	Title: styled.h1(
		({ theme }) => `
            ${theme.typography.title.t6}
            color: ${theme.colors.build.gray.g600};
            margin: 0;
            text-transform: uppercase;
        `
	),
	Back: styled.div<IStyle>(
		({ theme, transparent }) => `
        margin-right: 16px;
        button {
            background: transparent;
            border: none;
            color: ${
							transparent
								? theme.colors.build.dark.primary
								: theme.colors.build.gray.g300
						};
            display: block;
            font-size: 24px;
            line-height: normal;
            padding: 0;

            i {
                display: block;
            }
        }
    `
	),
	Logo: styled.div`
		position: relative;

		img {
			height: 24px;
		}
	`,
	Menu: styled.button(
		({ theme }) => `
        background: transparent;
        border: none;
        color: ${theme.colors.build.dark.primary};
        font-size: 22px;
        line-height: 1;
        padding: 0;
    `
	),
	CardUser: styled.div(
		({ theme }) => `
        text-align: center;

        figure {
            border-radius: ${theme.decoration.border.radius.full};
            display: block;
            height: 80px;
            margin: 0 auto 16px;
            overflow: hidden;
            width: 80px;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }

        dl {
            margin: 0 0 32px;
            dt {
                font-size: 14px;
                font-weight: bold;
            }

            dd {
                color: ${theme.colors.build.gray.g400};
                font-size: 12px;
                font-style: italic;
            }
        }
    `
	),
};

export default HeaderPrivateStyle;
