import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { message, Spin } from 'antd';

import { useRedux } from 'src/redux';

import { MainService } from 'src/services/MainServices';

import CompleteTasksStyle from './CompleteTasksStyle';

import SizesChallenge from 'src/components/forms/SizesChallenge';
import { Button } from 'src/components/atoms';

interface ICompleteTasks {}

const CompleteTasks: React.FC<ICompleteTasks> = () => {
	const dispatch = useDispatch();

	const { subscription } = useRedux();

	const [loading, setLoading] = useState(false);

	const [weight, setWeight] = useState(0);
	const [abdomen, setAbdomen] = useState(0);
	const [hip, setHip] = useState(0);

	const onChangeSizes = useCallback((value: number, format: string): void => {
		switch (format) {
			case 'weight':
				setWeight(value);
				break;
			case 'abdomen':
				setAbdomen(value);
				break;
			case 'hip':
				setHip(value);
				break;

			default:
				break;
		}
	}, []);

	useEffect(() => {
		setWeight(subscription.weightStart);
		setAbdomen(subscription.abdomenStart);
		setHip(subscription.hipStart);
	}, [
		subscription.weightStart,
		subscription.abdomenStart,
		subscription.hipStart,
	]);

	const onFinishChallenge = () => {
		setLoading(true);
		const data = {
			weightEnd: weight,
			abdomenEnd: abdomen,
			hipEnd: hip,
		};
		MainService.fetch(
			`subscription/onboarding/${subscription._id}`,
			'patch',
			data
		).then((res) => {
			if (res.status === 'success') {
				dispatch({ type: 'SUBSCRIPTION_UPDATE', value: data });
				message.success('Obrigado por enviar seus dados');
			} else {
				message.error(
					'Erro ao finalizar seu desafio, tente novamente mais tarde'
				);
			}
			setLoading(false);
		});
	};

	return (
		<CompleteTasksStyle.Wrapper>
			<Spin spinning={loading}>
				<div className='container'>
					<CompleteTasksStyle.Article>
						<h1>DESAFIO CONCLUIDO</h1>
						<p>
							Agora queremos saber a sua evolução neste periodo, também vamos
							criar um resumo para que você possa compartilhar em suas redes
							para isso precisamos que você nos informe suas novas medidas:
						</p>
					</CompleteTasksStyle.Article>
					<SizesChallenge
						weight={weight}
						abdomen={abdomen}
						hip={hip}
						onChange={onChangeSizes}
					/>
					<div className='pt-2'>
						<Button container onClick={onFinishChallenge}>
							FINALIZAR DESAFIO
						</Button>
					</div>
				</div>
			</Spin>
		</CompleteTasksStyle.Wrapper>
	);
};

export default CompleteTasks;
