import styled from '@emotion/styled';

const ShoppingListStyle = {
	Header: styled.div`
		margin-bottom: 80px;
	`,

	Info: styled.div`
		margin: 24px 0 0;

		article {
			margin-bottom: -80px;
		}
	`,
};

export default ShoppingListStyle;
