import decode from 'jwt-decode';

const LOCALTOKEN = 'jr_token';
const LOCALUSER = 'jr_user';

interface TokenInterface {
	exp: number;
}

const AuthService = {
	isAuthenticated: (): boolean => {
		const token = localStorage.getItem(LOCALTOKEN);
		if (!token) {
			return false;
		}

		const { exp } = decode<TokenInterface>(token);
		if (exp < new Date().getTime() / 1000) {
			return false;
		}

		return true;
	},
	registerToken: (
		token: string,
		user: {
			_id?: string;
			name: string;
			email: string;
			phone?: string;
			imageUrl?: string;
		}
	): void => {
		localStorage.setItem(
			LOCALUSER,
			JSON.stringify({
				id: user._id,
				name: user.name,
				email: user.email,
				phone: user.phone,
			})
		);
		localStorage.setItem(LOCALTOKEN, token);
	},
	getToken: (): string | null => {
		return localStorage.getItem(LOCALTOKEN);
	},
	getUser: () => {
		const user = localStorage.getItem(LOCALUSER);

		return user ? JSON.parse(user) : {};
	},
	logout: (): void => {
		localStorage.clear();
		window.location.href = '/';
	},
};

export default AuthService;
