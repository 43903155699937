import styled from '@emotion/styled';

const ActivitiesStyle = {
	Wrapper: styled.div`
		position: relative;
	`,

	Header: styled.div`
		margin-bottom: 24px;
	`,

	Info: styled.div(
		({ theme }) => `
        border-bottom: 1px dashed transparent;

        ul{
            ${theme.typography.body.standard}
            display: flex;
            margin: 0;
            padding: 32px 0 24px;
            flex-wrap: wrap;
            justify-content: space-between;


            li{
                background: ${theme.colors.build.light.primary};
                border-radius: 100%;
                box-shadow: ${theme.decoration.shadow.default};
                font-size: 12px;
                font-style: italic;
                height: 56px;
                line-height: 1;
                list-style: none;
                margin: 0;
                padding: 9px 0 0;
                text-align: center;
                text-transform: uppercase;
                width: 56px;

                strong {
                    display: block; 
                    font-size: 24px;
                    font-weight: 900;
                    line-height: 1;
                    transform: translateX(-2px);
                }

                &.active {
                    background: ${theme.colors.build.brand.b003};
                }
            }
        }

        article {
            background: ${theme.colors.build.light.primary};
            border-radius: ${theme.decoration.border.radius.md};
            box-shadow: ${theme.decoration.shadow.default};
            margin-bottom: -66px;
            padding: 16px 24px 24px;
            text-align: center;

            h2 {
                ${theme.typography.title.t5}
                color: ${theme.colors.build.dark.primary};
                font-weight: 900;
                font-style: italic;
                margin: 0;
                text-transform: uppercase;
            }

            p {
                ${theme.typography.body.standard}
                margin: 0 auto;
                max-width: 280px;
                width: 100%;
            }
        }

        @media (max-width: 480px) {
            h1 {
                span {
                    padding: 0 24px 24px;
                }
            }

            ul {
                li {
                    font-size: 10px;
                    height: 40px;
                    line-height: 0.8;
                    padding-top: 8px;
                    width: 40px;

                    strong {
                        font-size: 18px;
                        line-height: 0.8;
                    }
                }
            }
        }
    `
	),
};

export default ActivitiesStyle;
