import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useRedux } from 'src/redux';
import { IShoppingList } from 'src/interfaces/ShoppingListInterface';

import { MainService } from 'src/services/MainServices';

import ShoppingListCardsStyle from './ShoppingListCardsStyle';

import ShoppingListCard from 'src/components/common/ShoppingListCards/ShoppingListCard';

import { IconShopping6 } from 'src/components/atoms';

const ShoppingListCards: React.FC = () => {
	const navigate = useNavigate();

	const { challenge } = useRedux();

	const [data, setData] = useState<IShoppingList>();

	const getShoppingList = useCallback(async () => {
		const res = await MainService.fetch(
			`shopping-list/${challenge.shoppingList}`
		);

		if (res.status === 'success') {
			setData(res.data);
		} else {
			navigate('/app/dashboard');
		}
	}, [challenge.shoppingList, navigate]);

	useEffect(() => {
		if (challenge.shoppingList) {
			getShoppingList();
		}
	}, [challenge.shoppingList, getShoppingList]);

	return data ? (
		<div className='container'>
			{data.items.map((item) => (
				<ShoppingListCard item={item} sl={data} key={item._id} />
			))}
			<ShoppingListCardsStyle.Danger>
				<div className='row'>
					<div className='col-3'>
						<figure>
							<IconShopping6 />
						</figure>
					</div>
					<div className='col'>
						<h4>Proibidos</h4>
						<ul>
							<li>
								A água de coco deve ser utilizada apenas na receita de suco
								Verde Forte e Verde Amargo
							</li>
							<li>Bebida alcoólica</li>
							<li>Leite, queijo e derivados</li>
							<li>Refrigerante de qualquer tipo (mesmo o light e diet)</li>
						</ul>
					</div>
				</div>
			</ShoppingListCardsStyle.Danger>
		</div>
	) : (
		<></>
	);
};

export default ShoppingListCards;
