import { useState } from 'react';

import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';

import { Upload, Spin, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadProps } from 'antd/lib/upload/interface';

import ImgCrop from 'antd-img-crop';

import config from 'src/utils/config';

import { useRedux } from 'src/redux';

import { Button, HeaderBack } from 'src/components/atoms';

export const Style = {
	Wrapper: styled.div({
		padding: '40px 0 80px',
	}),

	RemoveImage: styled.div(({ theme }) => ({
		textAlign: 'center',

		button: {
			fontSize: '10px',

			padding: '8px 16px',
			textTransform: 'uppercase',
			display: 'inline-block',
			background: theme.colors.action.darger,
		},
	})),

	Photo: styled.aside(({ theme }) => ({
		padding: '16px 0 40px',
		textAlign: 'center',

		button: {
			alignItems: 'center',
			background: theme.colors.action.success,
			border: 'none',
			borderRadius: theme.decoration.border.radius.sm,
			color: theme.colors.build.light.primary,
			display: 'inline-flex',
			fontSize: '14px',
			padding: '12px 24px',
			textTransform: 'uppercase',
			span: {
				marginRight: '8px',
			},
		},
	})),

	Preview: styled.figure(({ theme }) => ({
		borderRadius: theme.decoration.border.radius.sm,
		overflow: 'hidden',
		position: 'relative',

		span: {
			color: '#fff',
			display: 'block',
			fontSize: '60px',
			left: '50%',
			position: 'absolute',
			textShadow: '0 0 10px rgba(0, 0, 0, 1)',
			top: '50%',
			transform: 'translate(-50%, -50%)',
			zIndex: 4,
		},

		image: {
			position: 'relative',
			zIndex: 1,
		},
	})),
};

export const OnBoardingPhoto = ({ backStep, nextStep }) => {
	const dispatch = useDispatch();

	const { user, subscription } = useRedux();

	const [isLoading, setLoading] = useState(false);

	const settingsUpload: UploadProps = {
		name: 'file',
		action: `${config.apiUrl}/cloudnary`,
		showUploadList: false,

		onChange: (info: UploadChangeParam) => {
			const { status } = info.file;

			setLoading(true);

			if (status !== 'uploading') {
				setLoading(true);
			}

			if (status === 'done') {
				if (info.file.response.status === 'success') {
					const dataImage = {
						photoStart: info.file.response.result.secure_url,
					};

					dispatch({
						type: 'SUBSCRIPTION_UPDATE',
						value: dataImage,
					});

					message.success(`Upload de ${info.file.name} feito com sucesso.`);
				} else {
					message.error(`Erro ao fazer o upload de ${info.file.name}.`);
				}
				setLoading(false);
			} else if (status === 'error') {
				setLoading(false);
				message.error(`Erro ao fazer o upload de ${info.file.name}.`);
			}
		},
	};

	const removePhoto = () => {
		dispatch({
			type: 'SUBSCRIPTION_UPDATE',
			value: {
				photoStart: '',
			},
		});
	};

	const onFinish = () => {
		nextStep();
	};
	return (
		<Spin spinning={isLoading}>
			<Style.Wrapper>
				<div className='container'>
					<HeaderBack title='Foto' back={backStep}>
						A transformação é um processo, não um evento. Para isso, insira uma
						foto sua e veja como foi o seu processo no final do desafio.
					</HeaderBack>

					<Style.Photo>
						<ImgCrop aspect={4 / 6}>
							<Upload {...settingsUpload}>
								{subscription.photoStart ? (
									<Style.Preview>
										<span className='ri-image-edit-line' />

										<img src={subscription.photoStart} alt={user.name} />
									</Style.Preview>
								) : (
									<button>
										<span className='ri-image-edit-line' /> Adicionar nova foto
									</button>
								)}
							</Upload>
						</ImgCrop>

						{subscription.photoStart && (
							<Style.RemoveImage>
								<button onClick={() => removePhoto()}>Remover Imagem</button>
							</Style.RemoveImage>
						)}
					</Style.Photo>

					<Button container onClick={onFinish}>
						{subscription.photoStart ? 'Continuar' : 'Pular'}
					</Button>
				</div>
			</Style.Wrapper>
		</Spin>
	);
};
