import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { message } from 'antd';

import {
	IShoppingList,
	IShoppingListItems,
} from 'src/interfaces/ShoppingListInterface';

import { useRedux } from 'src/redux';

import { MainService } from 'src/services/MainServices';
import { Mixpanel } from 'src/services/Mixpanel';

import ShoppingListCardStyle from './ShoppingListCardStyle';

import { ListHeader } from 'src/components/organisms';

interface IShoppingListCard {
	item: IShoppingListItems;
	sl: IShoppingList;
}

const ShoppingListCard: React.FC<IShoppingListCard> = ({ item, sl }) => {
	const [show, setShow] = useState(false);

	const { subscription } = useRedux();

	const dispatch = useDispatch();

	const isCheck = (id: string): boolean => {
		if (subscription.shoppingList && subscription.shoppingList.items.length) {
			const list = subscription.shoppingList.items.find(
				(i) => i.title === item.title
			);

			if (list) {
				return list.products.find((i) => i._id === id)?.checked || false;
			}

			return false;
		}
		return false;
	};

	const countChecked = (): number => {
		let checked = 0;
		if (subscription.shoppingList) {
			const indexItem = subscription.shoppingList.items.findIndex(
				(i) => i._id === item._id
			);

			if (subscription.shoppingList.items[indexItem]) {
				subscription.shoppingList.items[indexItem].products.forEach((i) => {
					checked += i.checked ? 1 : 0;
				});
			}
		}
		return checked;
	};

	const toggleCheckList = (id: string): void => {
		let shoppingList;

		if (subscription.shoppingList && subscription.shoppingList.items.length) {
			shoppingList = subscription.shoppingList;

			const indexItems = shoppingList.items.findIndex(
				(i) => i.title === item.title
			);

			if (indexItems >= 0) {
				const indexProduct = shoppingList.items[indexItems].products.findIndex(
					(i) => i._id === id
				);

				if (indexProduct >= 0) {
					shoppingList.items[indexItems].products[indexProduct].checked =
						!shoppingList.items[indexItems].products[indexProduct].checked;
				} else {
					const product = sl.items[indexItems].products.find(
						(i) => i._id === id
					);
					shoppingList.items[indexItems].push({
						...product,
						checked: true,
					});
				}
			} else {
				const items = sl.items.find((i) => i.title === item.title);
				const indexProduct = items?.products.findIndex((i) => i._id === id);

				if (indexProduct && indexProduct >= 0) {
					shoppingList.items.push({
						...items,
						products: items?.products.map((i) => {
							if (i._id === id) {
								return { ...i, checked: true };
							}
							return i;
						}),
					});

					shoppingList.items[indexItems].products[indexProduct].checked =
						!shoppingList.items[indexItems].products[indexProduct].checked;
				}
			}
		} else {
			shoppingList = sl;

			const indexItems = shoppingList.items.findIndex(
				(i) => i.title === item.title
			);

			if (indexItems >= 0) {
				const indexProduct = shoppingList.items[indexItems].products.findIndex(
					(i) => i._id === id
				);

				if (indexProduct >= 0) {
					shoppingList.items[indexItems].products[indexProduct].checked =
						!shoppingList.items[indexItems].products[indexProduct].checked;
				}
			}
		}

		dispatch({
			type: 'SUBSCRIPTION_UPDATE',
			value: { shoppingList: shoppingList },
		});

		MainService.fetch(`subscription/onboarding/${subscription._id}`, 'patch', {
			...subscription,
			shoppingList: shoppingList,
		}).then((res) => {
			if (res.status !== 'success') {
				message.error(
					'Erro ao atualizar sua lista de compras, tente novamente mais tarde'
				);
			}
			checkAllComplete();
		});
	};

	const checkAllComplete = (): void => {
		let completed = true;
		subscription.shoppingList?.items.forEach((itemsList) => {
			const total = itemsList.products.length;
			let checkItems = 0;
			itemsList.products.forEach((product) => {
				checkItems += product.checked ? 1 : 0;
			});
			if (checkItems === 0 || checkItems !== total) {
				completed = false;
			}
		});

		if (completed) {
			Mixpanel.track('Lista de Compras', {
				completed: true,
				name: item.title,
				id: item._id,
			});
		}
	};

	return (
		<ShoppingListCardStyle.Wrapper>
			<ShoppingListCardStyle.Header onClick={() => setShow(!show)}>
				<ListHeader
					{...{
						icon: item.title,
						title: item.title,
						items: item.products.length,
						itemsChecked: countChecked(),
						itemLabel: 'Itens comprados',
						open: show,
					}}
				/>
			</ShoppingListCardStyle.Header>
			<ShoppingListCardStyle.Content show={show}>
				<ul>
					{item.products.map((product) => (
						<li
							className={isCheck(product._id) ? 'checked' : ''}
							onClick={() => toggleCheckList(product._id)}
							key={product._id}>
							<strong>{product.name}</strong>
							<small>{product.amount}</small>

							<span className='radio'>
								<i
									className={`ri-checkbox${
										isCheck(product._id) ? '' : '-blank'
									}-circle-line`}></i>
							</span>
						</li>
					))}
				</ul>
			</ShoppingListCardStyle.Content>
		</ShoppingListCardStyle.Wrapper>
	);
};

export default ShoppingListCard;
