import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import { message, Spin } from 'antd';
import styled from '@emotion/styled';

import { useRedux } from 'src/redux';

import { MainService } from 'src/services/MainServices';

import { Button } from '../atoms';

interface IToken {
	idProduct: string;
	exp: number;
}

const Header = styled.dl({
	margin: '0 0 24px',
	padding: 0,

	dt: {
		fontSize: 20,
		fontWeight: 'bold',
		margin: 0,
		padding: 0,
		textTransform: 'uppercase',
	},

	dd: {
		margin: 0,
		padding: 0,
	},
});

export const SignupChallengeConfirm = () => {
	const dispatch = useDispatch();

	const [idChallenge, setIdChallenge] = useState('');

	const [isLoading, setLoading] = useState(false);

	const [token, setToken] = useState<IToken>();

	const {
		misc: { tokenSignUp },
		user,
	} = useRedux();

	const onBack = () => {
		dispatch({
			type: 'UPDATE_TOKEN_SIGN_UP',
			value: null,
		});
	};

	const onSubscribe = async () => {
		setLoading(false);

		const res = await MainService.fetch('subscription/user', 'post', {
			...user,
			idChallenge: idChallenge,
			token: tokenSignUp,
			idOrderProduct: token?.idProduct,
		});

		if (res.status === 'success') {
			dispatch({ type: 'UPDATE_MISC_STATUS', value: 'onboarding' });

			message.success('Cadastro realizado com sucesso');
		} else {
			message.error(
				res.data.message ||
					'Erro em nossos servidores, tente novamente mais tarde.'
			);
			setLoading(false);
		}
	};

	const getChallenge = useCallback(async () => {
		setLoading(true);
		const res = await MainService.fetch('challenge/active', 'GET');
		setLoading(false);
		if (res.status === 'success') {
			setIdChallenge(res.data._id);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		getChallenge();
	}, [getChallenge]);

	useEffect(() => {
		if (tokenSignUp) {
			const tokenDecode = decode<IToken>(tokenSignUp);

			if (tokenDecode.exp < new Date().getTime() / 1000) {
				onBack();

				message.warn('Insira novamente o código do produto');
			}

			setToken(tokenDecode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenSignUp]);

	return (
		<div className='container'>
			<Spin spinning={isLoading}>
				<Header>
					<dt>Produto Válido</dt>
					<dd>
						Seu desafio está prestes a iniciar, seu desafio começa na próxima
						segunda-feira. Clique no botão abaixo e confirme sua inscrição.
					</dd>
				</Header>

				<Button onClick={onSubscribe} container>
					CONFIRMAR INSCRIÇÃO
				</Button>

				<div className='pt-3'>
					<Button onClick={onBack} container color='transparent' format='ghost'>
						VOLTAR
					</Button>
				</div>
			</Spin>
		</div>
	);
};
