import React from 'react';

import HeaderBackStyle from './HeaderBackStyle';

import ArrowLeft from 'src/assets/images/arrow-left-color.png';

import { ReactComponent as LogoHorizontal } from 'src/assets/images/logo-horizontal.svg';

export const HeaderBack: React.FC<{
	back?: () => void;
	hideBack?: boolean;
	title?: string;
	logo?: boolean;
}> = ({ back, hideBack, title, children, logo }) => {
	const goBack = (): void => {
		if (back) {
			back();
		} else {
			window.history.back();
		}
	};

	return (
		<HeaderBackStyle.Wrapper>
			{!hideBack ? (
				<div className='mb-3'>
					<div className='row align-items-center'>
						<div className='col'>
							<HeaderBackStyle.ButtonBack
								type='button'
								onClick={() => goBack()}>
								<img src={ArrowLeft} alt='Voltar' />
							</HeaderBackStyle.ButtonBack>
						</div>
						{logo && (
							<div className='col-auto'>
								<HeaderBackStyle.Logo>
									<LogoHorizontal />
								</HeaderBackStyle.Logo>
							</div>
						)}
					</div>
				</div>
			) : null}

			<HeaderBackStyle.Title>{title}</HeaderBackStyle.Title>
			<HeaderBackStyle.Content>{children}</HeaderBackStyle.Content>
		</HeaderBackStyle.Wrapper>
	);
};
