import React from 'react';

import SignForm from 'src/layout/SignLayout/SignForm';

import SignUser from 'src/components/forms/SignUser';

const SignInvoice: React.FC = () => (
	<SignForm>
		<SignUser />
	</SignForm>
);

export default SignInvoice;
