import styled from '@emotion/styled';

const ShoppingListCardsStyle = {
	Wrapper: styled.div`
		position: relative;
	`,

	Danger: styled.div(
		({ theme }) => `
		background: ${theme.colors.build.light.primary};
		box-shadow: ${theme.decoration.shadow.default};
		border-radius: ${theme.decoration.border.radius.md};
		padding: 24px;
		margin-bottom: 40px;

		figure {
			svg {
				fill: ${theme.colors.action.darger};
				height: auto;
				width: 100%;
			}
		}


		h4 {
			${theme.typography.title.t3}
			color: ${theme.colors.build.dark.primary};
			font-weight: 900;
			font-style: italic;
			margin: 0;
			text-transform: uppercase;
		}

		ul {
			margin: 0 0 0 12px;
			padding: 0;
			li {
				${theme.typography.body.short}
				margin: 8px 0 0;
			}
		}
	`
	),
};

export default ShoppingListCardsStyle;
