import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { message, Spin } from 'antd';

import { useRedux } from 'src/redux';

import { MainService } from 'src/services/MainServices';

import SizesChallenge from 'src/components/forms/SizesChallenge';

import { Button } from 'src/components/atoms';

interface IProfileSizes {
	onClose: () => void;
}

const ProfileSizes: React.FC<IProfileSizes> = ({ onClose }) => {
	const dispatch = useDispatch();

	const { subscription } = useRedux();

	const [loading, setLoading] = useState(false);

	const [weight, setWeight] = useState(0);
	const [abdomen, setAbdomen] = useState(0);
	const [hip, setHip] = useState(0);

	const onChangeSizes = useCallback((value: number, format: string): void => {
		switch (format) {
			case 'weight':
				setWeight(value);
				break;
			case 'abdomen':
				setAbdomen(value);
				break;
			case 'hip':
				setHip(value);
				break;

			default:
				break;
		}
	}, []);

	const onFinish = () => {
		setLoading(true);
		const data = {
			weightStart: weight,
			abdomenStart: abdomen,
			hipStart: hip,
		};
		MainService.fetch(
			`subscription/onboarding/${subscription._id}`,
			'patch',
			data
		).then((res) => {
			if (res.status === 'success') {
				dispatch({ type: 'SUBSCRIPTION_UPDATE', value: data });
				message.success('Dados atualizados');
				onClose();
			} else {
				message.error(
					'Erro ao finalizar seu desafio, tente novamente mais tarde'
				);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		setWeight(subscription.weightStart);
		setAbdomen(subscription.abdomenStart);
		setHip(subscription.hipStart);
	}, [
		subscription.weightStart,
		subscription.abdomenStart,
		subscription.hipStart,
	]);

	return (
		<Spin spinning={loading}>
			<SizesChallenge
				weight={weight}
				abdomen={abdomen}
				hip={hip}
				onChange={onChangeSizes}
			/>
			<div className='pt-2'>
				<Button container onClick={onFinish}>
					SALVAR DADOS
				</Button>
			</div>
		</Spin>
	);
};

export default ProfileSizes;
