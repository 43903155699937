import React from 'react';

import SignLayoutStyle from './SignLayoutStyle';

const SignFooter: React.FC = () => {
	return (
		<SignLayoutStyle.Form.Footer>
			Todos os Direitos Reservados © Jan Rosê Cosméticos
		</SignLayoutStyle.Form.Footer>
	);
};

export default SignFooter;
