import styled from '@emotion/styled';

const ShoppingListCardStyle = {
	Wrapper: styled.div(
		({ theme }) => `
		background: ${theme.colors.build.light.primary};
		border-radius: ${theme.decoration.border.radius.md};
		box-shadow: ${theme.decoration.shadow.default};
		margin-bottom: 24px;
		overflow: hidden;
		position: relative;
	`
	),

	Header: styled.div`
		padding: 24px;
	`,

	Content: styled.div<{ show: boolean }>(
		({ theme, show }) => `
		border-top: 1px solid ${theme.colors.build.gray.g200};
		display: ${show ? 'block' : 'none'};
		padding: 0;
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				line-height: normal;
				padding: 16px 32px;
				position: relative;
				strong {
					${theme.typography.body.standard}
					display: block;
					font-weight: bold;
				}

				.radio {
					display: block;
					font-size: 24px;
					line-height: normal;
					margin-top: -12px;
					position: absolute;
					right: 32px;
					top: 50%;
					i {
						display: block;
					}
				}

				&:nth-of-type(even) {
					background: ${theme.colors.build.gray.g100};
				}

				&.checked {					
					text-decoration: line-through;

					.radio {
						color: ${theme.colors.action.success};
					}
				}
			}
		}
	`
	),
};

export default ShoppingListCardStyle;
