import styled from '@emotion/styled';

const ChallengeProgressStyle = {
	Wrapper: styled.div(
		({ theme }) => `
            background: ${theme.colors.build.light.primary};
            border-radius: ${theme.decoration.border.radius.md};
            box-shadow: ${theme.decoration.shadow.default};
            margin: 0;
            padding: 20px;
            position: relative;
        `
	),

	Title: styled.h3(
		({ theme }) => `
        ${theme.typography.title.t4}
        color: ${theme.colors.build.dark.primary};
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
    `
	),

	Label: styled.div(
		({ theme }) => `
        ${theme.typography.body.short}
        
        color: ${theme.colors.build.dark.primary};
        font-size: 11px;
    `
	),

	Status: styled.div(
		({ theme }) => `
        align-items: center;
        background: linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.tertiary});
        border-radius: ${theme.decoration.border.radius.sm};
        color: ${theme.colors.build.dark.primary};
        display: flex;
        font-size: 12px;
        font-weight: bold;
        justify-content: center;
        padding: 8px;
    `
	),

	Ilustra: styled.figure`
		position: absolute;
		right: 0;
		bottom: 0;
		margin: 0;
		width: 35%;
		img {
			height: auto;
			width: 100%;
		}
	`,

	Progress: styled.div<{ progress: number }>(
		({ theme, progress }) => `
        background: ${theme.colors.build.gray.g000};
        border-radius: ${theme.decoration.border.radius.sm};
        height: 8px;
        margin-top: 16px;
        overflow: hidden;
        position: relative;

        &:before {
            background: linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.tertiary});
            content: ' ';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: ${progress}%;
        }
    `
	),
};

export default ChallengeProgressStyle;
