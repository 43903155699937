import styled from '@emotion/styled';

const SizesChallengeStyle = {
	Wrapper: styled.div`
		position: relative;
	`,

	WrapperSizes: styled.dl`
		margin: 0 0 32px;
		text-align: center;
	`,

	Title: styled.dt(
		({ theme }) => `
        ${theme.typography.title.t6}
        font-weight: 900;
        margin-bottom: 14px;
        text-transform: uppercase;
    `
	),

	Edit: styled.dd`
		padding: 0;
	`,

	Slider: styled.div`
		margin: 0 auto;
		width: 300px;
	`,

	Cursor: styled.div(
		({ theme }) => `
            background: ${theme.colors.build.dark.primary};
            width: 4px;
            height: 34px;
            top: auto;
            bottom: 100px;
        `
	),

	Value: styled.div(
		({ theme }) => `
        ${theme.typography.title.t2}
        line-height: normal;

        sup {
            ${theme.typography.body.standard}
            line-height: normal;
            margin-left: 4px;
            position: relative;
            top: -10px;
        }
    `
	),
};

export default SizesChallengeStyle;
