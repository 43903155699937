import styled from '@emotion/styled';

const ReceitasCardStyle = {
	Wrapper: styled.div(
		({ theme }) => `
            border-radius: ${theme.decoration.border.radius.md};
            color: ${theme.colors.build.light.primary};
            margin-bottom: 24px;
            overflow: hidden;
            position: relative;

            aside {
                bottom: 0;
                left: 0;
                padding: 32px 24px;
                position: absolute;
                width: 100%;
                z-index: 2;

                h2 {
                    ${theme.typography.title.t4}
                    color: ${theme.colors.build.light.primary};
                    line-height: normal;
                    margin: 0;
                    position: relative;

                    .tags {
                        align-items: center;
                        bottom: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 4px;
                        position: absolute;
                        width: 100%;

                        em {
                            background: ${theme.colors.build.brand.b003};
                            border-bottom-left-radius: ${theme.decoration.border.radius.sm};
                            border-top-right-radius: ${theme.decoration.border.radius.sm};
                            color: ${theme.colors.build.dark.primary};
                            display: inline-block;
                            font-size: 9px;
                            font-style: normal;
                            font-weight: bold;
                            line-height: normal;
                            margin: 0 16px 4px 0;
                            padding: 4px 8px;
                        }
                    }
                }

                span {
                    ${theme.typography.body.short}
                    align-items: center;
                    color: ${theme.colors.build.light.primary};
                    display: flex;
                    line-height: normal;

                    i {
                        margin-right: 4px;
                        font-size: 24px;
                    }
                }
            }

            figure {
                margin: 0;
                position: relative;

                img {
                    position: relative;
                }

                &:before {
                    background: linear-gradient(180deg, rgba(0,0,0,0.1), rgba(0,0,0,0.7));
                    content: '';
                    display: block;
                    height: 100%;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                }
            }
        `
	),
};

export default ReceitasCardStyle;
