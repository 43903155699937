import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Form, Input, message, Modal } from 'antd';

import { Mixpanel } from 'src/services/Mixpanel';

import { MainService } from 'src/services/MainServices';

import SignForm from 'src/layout/SignLayout/SignForm';

import { Button } from 'src/components/atoms';

import SignStyle from './SignStyle';

const SignPass: React.FC = () => {
	const [form] = Form.useForm();

	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState<
		'' | 'success' | 'warning' | 'error' | 'validating' | undefined
	>('');

	const navigate = useNavigate();

	const onFinish = async (values: { email: string }): Promise<void> => {
		setLoading(true);

		const res = await MainService.fetch('users/pass', 'post', {
			email: values.email,
			url: process.env.REACT_APP_URL + 'atualizar-senha/',
		});

		Mixpanel.track('Login - Senha', {
			status: res.status,
			email: values.email,
		});

		if (res.status === 'success') {
			navigate('/');
			message.success(res.data.message);
		} else {
			Modal.error({
				title: 'Esqueci minha senha!',
				content:
					res.data.message ||
					'Erro ao realizar processo de atualização de senha.',
			});
		}
		setStatus(res.status);
		setLoading(false);
	};

	return (
		<SignForm>
			<SignStyle.HeroText>
				<dt>Esqueci minha senha</dt>
				<dd>
					Caso tenha esquecido sua senha, não tem problema, enviaremos um link
					no seu email para iniciar o processo de recuperação de senha.
				</dd>
			</SignStyle.HeroText>
			<Form
				layout='vertical'
				form={form}
				onFinish={onFinish}
				data-testid='form-pass'>
				<Form.Item
					label='Email'
					name='email'
					hasFeedback
					validateStatus={status}
					rules={[
						{ required: true, message: 'Digite um válido válido' },
						{ type: 'email', message: 'Digite um email válido' },
					]}>
					<Input
						placeholder='Digite seu Email'
						disabled={loading}
						data-testid='input-email'
					/>
				</Form.Item>

				<div className='row align-items-center'>
					<div className='col'>
						<Button container disabled={loading} loaded={loading}>
							CONTINUAR
						</Button>
					</div>
					<div className='col-auto'>
						<Link to='/'>
							<SignStyle.LinkSmall>Lembrou sua senha?</SignStyle.LinkSmall>
						</Link>
					</div>
				</div>
			</Form>
		</SignForm>
	);
};

export default SignPass;
