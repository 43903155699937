import styled from '@emotion/styled';

import bg from 'src/assets/images/background-green.jpg';

const ProfileStyle = {
	Header: styled.div`
		margin-bottom: 80px;
	`,

	CardHeader: styled.div(
		({ theme }) => `
        background: ${theme.colors.build.light.primary};
        border-radius: ${theme.decoration.border.radius.md};
        box-shadow: ${theme.decoration.shadow.default};
        margin-bottom: -80px;
        margin-top: 40px;
        padding: 16px 24px;
        position: relative;

        figure {
            margin: 0;
            position: relative;

            span {
                align-items: center;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                color: ${theme.colors.build.dark.primary};
                display: flex;
                height: 32px;
                justify-content: center;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 32px;
            }

            img {
                border-radius: 100%;
            }

            &:hover {
                span {
                    display: none;
                }
            }
        }

        p {
            ${theme.typography.body.short}
            font-weight: normal;
            margin: 0;

            strong {
                ${theme.typography.body.standard}
                font-weight: 900;
                text-transform: uppercase;
            }
        }
	`
	),

	EditProfile: styled.button(
		({ theme }) => `
        align-items: center;
        background: transparent;
        border: none;
        color: ${theme.colors.build.gray.g400};
        display: flex;
        font-size: 18px;
        height: 40px;
        justify-content: center;
        line-height: normal;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        z-index: 5;
    `
	),

	Sizes: styled.div(
		({ theme }) => `
        margin-bottom: 24px;
        ul {
            box-shadow: ${theme.decoration.shadow.default};
            border-radius: ${theme.decoration.border.radius.md};
            margin: 0;
            overflow: hidden;
            padding: 0;
            li {
                background: ${theme.colors.build.light.primary};
                ${theme.typography.body.standard}
                align-items: center;
                display: flex;
                font-weight: 900;
                margin: 0;
                padding: 16px 24px;
                position: relative;

                i {
                    margin-right: 16px;
                    width: 32px;

                    img {
                        display: block;
                    }
                }

                span {
                    display: block;
                    font-weight: normal;
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:nth-of-type(even) {
                    background: ${theme.colors.build.gray.g100};
                }
            }
        }
    `
	),

	Wather: styled.div(
		({ theme }) => `
        background: ${theme.colors.build.light.primary};
        border-radius: ${theme.decoration.border.radius.md};
        box-shadow: ${theme.decoration.shadow.default};
        padding: 24px 16px;
        text-align: center;

        h2 {
            ${theme.typography.title.t5}
            color: ${theme.colors.build.dark.primary};
            font-weight: 900;
            font-style: italic;
            margin: 0;
            text-transform: uppercase;
        }

        p {
            ${theme.typography.body.standard}
            margin: 0 auto;
            max-width: 280px;
            width: 100%;
        }
        `
	),

	Share: {
		Wrapper: styled.div`
			align-items: center;
			background: url(${bg}) no-repeat center top;
			background-size: cover;
			display: flex;
			justify-content: center;
			min-height: 920px;
			padding: 40px 0;
			position: relative;
			text-align: center;
			width: 100%;
		`,

		Logo: styled.div`
			margin: 0 auto 40px;
			width: 120px;
		`,

		Avatar: styled.div`
			margin: 0 auto 24px;
			width: 200px;

			img {
				border-bottom: 6px solid #fff;
				border-radius: 100%;
			}
		`,

		Title: styled.h3(
			({ theme }) => `
                ${theme.typography.title.t3}
                font-weight: 900;
                line-height: normal;
                margin: 0 0 56px;
                position: relative;
                text-align: center;
                text-transform: uppercase;

                span {
                    padding: 0 8px;
                    position: relative;

                    &:before {
                        background: ${theme.colors.build.light.primary};
                        content: ' ';
                        display: block;
                        height: 12px;
                        left: 0;
                        position: absolute;
                        bottom: -2px;
                        width: 100%;
                    }

                    em {
                        font-style: normal;
                        position: relative;
                        z-index: 3;
                    }
                }

                
            `
		),

		Slogan: styled.div`
			position: relative;
			padding: 0 50px;
			margin-bottom: 72px;
		`,

		JanRose: styled.div`
			display: inline-block;
			max-width: 50%;
		`,
	},
};

export default ProfileStyle;
