import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as htmlToImage from 'html-to-image';

import { Upload, Spin, message, Drawer } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadProps } from 'antd/lib/upload/interface';

import ImgCrop from 'antd-img-crop';

import config from 'src/utils/config';

import { MainService } from 'src/services/MainServices';
import { Mixpanel } from 'src/services/Mixpanel';

import { useRedux } from 'src/redux';

import ProfileStyle from './ProfileStyle';

import HeaderPages from 'src/components/common/HeaderPages';
import { Button } from 'src/components/atoms';

import iconWeight from 'src/assets/icons/result-weight.png';
import iconAbdomen from 'src/assets/icons/result-abdomen.png';
import iconHip from 'src/assets/icons/result-hip.png';

import ProfileEdit from './ProfileEdit';
import ProfileSizes from './ProfileSizes';
import ProfileShare from './ProfileShare';

const Profile: React.FC = () => {
	const dispatch = useDispatch();

	const { user, subscription } = useRedux();
	const [loading, setLoading] = useState(false);
	const [showShare, setShowShare] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showEditSizes, setShowEditSizes] = useState(false);

	const settingsUpload: UploadProps = {
		name: 'file',
		action: `${config.apiUrl}/cloudnary`,
		showUploadList: false,

		onChange: (info: UploadChangeParam) => {
			const { status } = info.file;

			if (status !== 'uploading') {
				setLoading(true);
			}

			if (status === 'done') {
				if (info.file.response.status === 'success') {
					const dataImage = { imageUrl: info.file.response.result.secure_url };
					dispatch({
						type: 'USER_UPDATE',
						value: dataImage,
					});

					MainService.fetch('users/profile', 'post', dataImage);
					message.success(`Upload de ${info.file.name} feito com sucesso.`);
				} else {
					message.error(`Erro ao fazer o upload de ${info.file.name}.`);
				}
				setLoading(false);
			} else if (status === 'error') {
				setLoading(false);
				message.error(`Erro ao fazer o upload de ${info.file.name}.`);
			}
		},
	};

	const handleShare = async (): Promise<void> => {
		setShowShare(true);
		setLoading(true);

		setTimeout(() => {
			Mixpanel.track('Compartilhar Convite', {
				completed: true,
				name: user.name,
				email: user.email,
				id: user._id,
			});

			const wrapper = document.getElementById('share-card');
			if (wrapper) {
				htmlToImage.toJpeg(wrapper).then((res) => {
					if (res) {
						var link = document.createElement('a');
						link.download = 'convite-rennovit.jpg';
						link.href = res;
						link.click();
					} else {
						message.error('Erro ao processar sua imagem');
					}
					setLoading(false);
					setShowShare(false);
				});
			}
		}, 1500);
	};

	return (
		<Spin spinning={loading}>
			{showShare ? (
				<div id='share-card'>
					<ProfileShare />
				</div>
			) : (
				<>
					<ProfileStyle.Header>
						<HeaderPages title='Meu Perfil'>
							<div className='container'>
								<ProfileStyle.CardHeader>
									<ProfileStyle.EditProfile
										onClick={() => setShowEdit(!showEdit)}>
										<i className='ri-pencil-line'></i>
									</ProfileStyle.EditProfile>
									<div className='row align-items-center'>
										<div className='col-4'>
											<ImgCrop>
												<Upload {...settingsUpload}>
													<figure>
														<span className='ri-image-edit-line' />
														<img
															src={
																user.imageUrl ||
																`https://avatar.oxro.io/avatar.svg?name=${encodeURI(
																	user.name || ''
																)}&background=000000&caps=3&bold=true`
															}
															alt={user.name}
														/>
													</figure>
												</Upload>
											</ImgCrop>
										</div>
										<div className='col-8'>
											<p>
												<strong>{user.name}</strong>
											</p>
											<p>{user.email}</p>
											<p>{user.phone}</p>
										</div>
									</div>
								</ProfileStyle.CardHeader>
							</div>
						</HeaderPages>
					</ProfileStyle.Header>
					<div className='container'>
						<ProfileStyle.Sizes onClick={() => setShowEditSizes(true)}>
							<ul>
								<li>
									<i>
										<img src={iconWeight} alt='Peso' />
									</i>
									{subscription.weightStart}kg
									<span className='ri-pencil-line' />
								</li>
								<li>
									<i>
										<img src={iconAbdomen} alt='Cintura' />
									</i>
									{subscription.abdomenStart}cm
									<span className='ri-pencil-line' />
								</li>
								<li>
									<i>
										<img src={iconHip} alt='Quadril' />
									</i>
									{subscription.hipStart}cm
									<span className='ri-pencil-line' />
								</li>
							</ul>
						</ProfileStyle.Sizes>

						<ProfileStyle.Wather>
							<article>
								<h2>Importante:</h2>
								<p>
									Conforme seu peso você precisa tomar{' '}
									<strong>
										{((subscription.weightStart * 35) / 1000)
											.toString()
											.replace('.', ',')}{' '}
										litros
									</strong>{' '}
									de água por dia.
								</p>
							</article>
						</ProfileStyle.Wather>

						<div className='mt-4 mb-5'>
							<Button container onClick={handleShare}>
								Compartilhar Convite
							</Button>
						</div>
					</div>

					<Drawer
						height={300}
						title='EDITAR SEUS DADOS'
						visible={showEdit}
						onClose={() => setShowEdit(!showEdit)}
						placement='bottom'>
						<ProfileEdit />
					</Drawer>

					<Drawer
						height={580}
						title='EDITAR MEDIDAS INICIAIS'
						visible={showEditSizes}
						onClose={() => setShowEditSizes(!showEditSizes)}
						placement='bottom'>
						<ProfileSizes onClose={() => setShowEditSizes(false)} />
					</Drawer>
				</>
			)}
		</Spin>
	);
};

export default Profile;
