import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Helpers } from 'src/utils/helpers';

import { Button } from 'src/components/atoms';

import ActiveHighlightStyle from './ActiveHighlightStyle';

import useSubscription from 'src/hooks/useSubscription';

const ActiveHighlight: React.FC = () => {
	const { dateStart } = useSubscription();
	const navigate = useNavigate();

	return (
		<ActiveHighlightStyle.Wrapper active={Helpers.activitieNumber(dateStart)}>
			<div className='row'>
				<div className='col-auto'>
					<dl>
						<dt>Atividade</dt>
						<dd>{Helpers.pad(Helpers.activitieNumber(dateStart), 2, '0')}</dd>
					</dl>
					<Button
						size='small'
						container
						onClick={() =>
							navigate(`/app/desafio/${Helpers.activitieNumber(dateStart)}`)
						}>
						ACESSAR
					</Button>
				</div>
			</div>
		</ActiveHighlightStyle.Wrapper>
	);
};

export default ActiveHighlight;
