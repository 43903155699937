import styled from '@emotion/styled';

import background from 'src/assets/images/background.jpg';

const OnBoardingLayoutStyle = {
	Wrapper: styled.div(
		({ theme }) => `
        background: url(${background}) no-repeat center;
        background-size: cover;
        min-height: inherit;
        position: relative;
        overflow-x: hidden;

        .enterActive,
        .exitActive{
            position: absolute;
            top: 0;
            width: 100%;
        }
    `
	),
};

export default OnBoardingLayoutStyle;
