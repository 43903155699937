import { IChallenge } from 'src/interfaces/ChallangeInterface';

const ChallengeReducer: (
	state: IChallenge,
	action: {
		type: 'CHALLENGE_UPDATE' | 'CHALLENGE';
		value: IChallenge;
	}
) => IChallenge = (
	state = {
		days: 7,
	},
	action
) => {
	if (action.type === 'CHALLENGE_UPDATE') {
		return { ...state, ...action.value };
	}

	return state;
};

export default ChallengeReducer;
