/* istanbul ignore next */
import axios, { AxiosError, AxiosResponse, Method } from 'axios';

import config from 'src/utils/config';

import AuthService from './AuthService';

export interface IResponse<T = any> {
	status: string;
	data?: T;
}

axios.interceptors.request.use((response) => {
	const responseAxios = response;

	if (response.params && response.params.key) return responseAxios;

	if (AuthService.getToken()) {
		responseAxios.headers.Authorization = `Bearer ${AuthService.getToken()}`;
	}

	return responseAxios;
});

export const MainService = {
	fetch: (
		url: string,
		method: Method = 'GET',
		data: any = null,
		params: object = {}
	) => {
		return axios({
			url: `${config.apiUrl}/${url}`,
			method: method,
			data,
			params,
		})
			.then((res: AxiosResponse) => res.data)
			.catch((error: AxiosError) => {
				if (error.response && error.response.data) {
					return error.response.data;
				} else {
					return {
						status: 500,
						data: {
							message: 'Erro na aplicação',
						},
					};
				}
			});
	},
	receitas: (params: object = {}) => {
		return axios({
			url: `${config.receitasUrl}`,
			method: 'GET',
			params: { ...params, key: 1 },
		})
			.then((res: AxiosResponse) => res.data)
			.catch((error: AxiosError) => {
				if (error.response && error.response.data) {
					return error.response.data;
				} else {
					return {
						status: 500,
						data: {
							message: 'Erro na aplicação',
						},
					};
				}
			});
	},
};
