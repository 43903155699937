import React, { useCallback, useEffect, useState } from 'react';

import { message, Spin } from 'antd';

import { IReceitasList } from 'src/interfaces/ReceitasInterface';

import { MainService } from 'src/services/MainServices';
import { Mixpanel } from 'src/services/Mixpanel';

import ReceitasStyle from './ReceitasStyle';

import HeaderPages from 'src/components/common/HeaderPages';
import HighlightHeader from 'src/components/common/HighlightHeader';

import ReceitasCard from './ReceitasCard';

interface ICategory {
	term_id: string;
	name: string;
	slug: string;
	term_group: string;
	term_taxonomy_id: string;
	taxonomy: string;
	parent: string;
	count: string;
}

const Receitas: React.FC = () => {
	const [data, setData] = useState<[IReceitasList]>();
	const [categories, setCategories] = useState<[ICategory]>();

	const [loading, setLoading] = useState(false);
	const [slug, setSlug] = useState('');

	const getReceitas = useCallback(async () => {
		setLoading(true);
		const res = await MainService.receitas({ action: 'receitas' });
		if (res) {
			setData(res);
		}
		setLoading(false);
	}, []);

	const getCategories = useCallback(async () => {
		const res = await MainService.receitas({ action: 'receitas_category' });

		if (res.length) {
			setCategories(res);
		}
	}, []);

	const handleFilter = async (slug: string): Promise<void> => {
		Mixpanel.track('Filtro Receitas', {
			slug: slug,
		});

		setLoading(true);
		setSlug(slug);
		const res = await MainService.receitas({
			action: 'receitas',
			category: slug,
		});

		setLoading(false);
		if (res) {
			setData(res);
		} else {
			message.warning('Nenhum item encontrado');
		}
	};

	useEffect(() => {
		getReceitas();
		getCategories();
	}, [getReceitas, getCategories]);
	return (
		<Spin spinning={loading}>
			<ReceitasStyle.Header>
				<HeaderPages title='Receitas'>
					<ReceitasStyle.Info>
						<div className='container'>
							<article>
								<HighlightHeader title='Dica da Day'>
									Organize e prepare suas receitas antes do ínicio do desafio,
									separe em porções congele e depois é só se deliciar quanto
									mais prático você deixar seu desafio melhor será o seu
									resultado.
								</HighlightHeader>
							</article>
						</div>
					</ReceitasStyle.Info>
				</HeaderPages>
			</ReceitasStyle.Header>

			{data && (
				<div className='container'>
					{categories && (
						<ReceitasStyle.Category>
							{categories.map((item) => (
								<div key={`category-receitas-${item.term_id}`}>
									<button
										onClick={() => handleFilter(item.slug)}
										className={slug === item.slug ? 'active' : ''}>
										{item.name}
									</button>
								</div>
							))}
						</ReceitasStyle.Category>
					)}
					{data.map((item, index) => (
						<ReceitasCard
							key={`receitas-card-${item.id}-${index}`}
							item={item}
						/>
					))}
				</div>
			)}
		</Spin>
	);
};

export default Receitas;
